import React from 'react';
import Iframe from 'react-iframe';

const EquipmentPDF = ({ pdfUrl, pdfLoading }) => {
	const height = window.innerHeight;

	return (
		<>
			{!pdfLoading ? (
				<Iframe url={pdfUrl} width='100%' height={height} frameBorder={0} />
			) : (
				<div className='d-flex align-items-center'>
					<div className='mr-2 text-muted'>Loading...</div>
					<div className='spinner spinner-primary mr-10'></div>
				</div>
			)}
		</>
	);
};

export default EquipmentPDF;
