import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import {
	getMagicLinkInfo,
	updateMagicLinkStatus,
} from '../modules/services/api';
import { capitalize } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const MagicLinkTable = ({ id }) => {
	const [linkInfo, setLinkInfo] = useState();
	const [showToaster, setShowToaster] = useState({});
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [refreshLoading, setRefreshLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		const getMagicLink = async () => {
			const magic_link = await getMagicLinkInfo(id);

			if (magic_link.success) {
				if (magic_link.data['entry']['status'] !== 'draft') {
					setDisabled(true);
				}
				setLinkInfo(magic_link.data['link']);
			}
		};
		getMagicLink();
	}, [showToaster, refresh]);

	const updateLinkStatus = async (e) => {
		e.preventDefault();

		setLoading(true);

		let data = {
			link_status: linkInfo['active'] === 'Yes' ? 'deactivate' : 'activate',
		};

		let response = await updateMagicLinkStatus(id, data);

		setLoading(false);

		if (response.success) {
			setShowToaster({ message: response.data, status: 'success' });
		} else {
			setShowToaster({ message: response.data, status: 'warning' });
		}

		console.log(response);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setShowToaster({});
	};

	const createTable = () => {
		let columns = [],
			datasource = {};

		Object.keys(linkInfo).map((key) => {
			datasource['key'] = '1';

			datasource[key] = `${linkInfo[key]}`;

			if (key === 'active') {
				columns.push({
					title: capitalize(key.split('_').join(' ')),
					dataIndex: key,
					key: key,
					align: 'center',
					render: (value) => (
						<div
							className={`py-1 px-5 rounded text-center ${
								value === 'Yes' ? 'bg-success' : 'bg-danger'
							} text-white`}>
							{value}
						</div>
					),
				});
			} else {
				columns.push({
					title: capitalize(key.split('_').join(' ')),
					dataIndex: key,
					key: key,
				});
			}
		});

		datasource = [datasource];

		return (
			<>
				<Table
					className='w-100'
					columns={columns}
					dataSource={datasource}
					scroll={{ x: 'fit-content' }}
					pagination={false}
					tableLayout={'auto'}
				/>
				{!disabled && (
					<div className='d-flex row'>
						<Button
							type='primary'
							size='large'
							className={`rounded border-0 mt-5 ${
								linkInfo['active'] === 'Yes' ? 'bg-danger' : 'bg-success'
							}`}
							autoFocus={false}
							loading={loading}
							onClick={(e) => {
								updateLinkStatus(e);
							}}
							disabled={disabled}>
							{linkInfo['active'] === 'Yes' ? 'Deactivate' : 'Reactivate'}
						</Button>
						<span className='px-2' />
						<Button
							type='primary'
							size='large'
							className='rounded border-0 mt-5'
							autoFocus={false}
							loading={refreshLoading}
							onClick={(e) => {
								setRefreshLoading(true);
								setRefresh(!refresh);
								setTimeout(() => {
									setRefreshLoading(false);
								}, 800);
							}}
							disabled={disabled}>
							Refresh
						</Button>
					</div>
				)}
			</>
		);
	};

	return linkInfo ? (
		<>
			{createTable()}
			{showToaster?.status === 'success' ? (
				<Snackbar
					open={showToaster?.message}
					anchorOrigin={{
						horizontal: 'right',
						vertical: 'top',
					}}
					autoHideDuration={4000}
					onClose={handleClose}
					disableWindowBlurListener={true}>
					<MuiAlert elevation={6} variant='filled' severity={showToaster.satus}>
						{showToaster.message}
					</MuiAlert>
				</Snackbar>
			) : null}
		</>
	) : (
		<div className='d-flex justify-content-center w-100'>
			<div className='spinner spinner-primary m-30'></div>
		</div>
	);
};

export default MagicLinkTable;
