import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-6.scss";

 const LinkExpired = () => {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg6.jpg")})`,
        }}
      >
        <div className="container d-flex flex-row-fluid flex-column text-center justify-content-md-start p-12">
          <h1 className="error-title font-weight-boldest text-warning mt-8 mt-md-0 mb-8">
            Oops!
          </h1>
          <p className="font-weight-boldest display-4 text-white">
            This link has expired!
          </p>
          <p className="font-size-h3 text-white">
            If you are facing any issue, contact us at <br/> <a href="mailto:customercredit@tekmarkgroup.com" className="text-warning" style={{ textDecoration: 'underline' }}>customercredit@tekmarkgroup.com</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LinkExpired;
