import React, { useEffect, useCallback } from 'react';
import {
	getFormPendingForActions,
	magicLinkAuth,
} from '../../modules/services/api';
import { useHistory, useParams, useLocation, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getViewForm } from '../../modules/services/api.js';
import * as auth from './../../../redux/reducers/user.reducer.js';
import { injectIntl } from 'react-intl';

const OneTimeForm = (props) => {
	const { intl } = props;
	const history = useHistory();
	const { formType } = useParams();
	const queryParams = useLocation().search;
	const token = new URLSearchParams(queryParams).get('token');
	const email = new URLSearchParams(queryParams).get('email');

	const viewForm = useCallback(async (id, formType, isNewEntry) => {
		let url = 'form/entries',
			formData = id
				? await getViewForm(id, 'customer-credit-requistion', url).then(
						(data) => data.data
				  )
				: {};

		if (formData) {
			history.push({
				pathname: `/oneTime/${formType}?token=${token}&email=${email}`,
				params: { formInfo: formData, isNewEntry },
			});
		} else {
			// formData.type = formType;
			history.push({
				pathname: `/oneTime/${formType}?token=${token}&email=${email}`,
			});
		}
	});

	useEffect(() => {
		const fetchform = async () => {
			const response = await magicLinkAuth(token, email);

			if (response.success) {
				props.login(token);
				const data = {
					status: 'draft',
					pageNumber: 1,
					pageSize: 10,
					isNotEqual: false,
					searchValue: null,
				};
				let drafts = await getFormPendingForActions(data, null);

				if (drafts.count) {
					let id = drafts?.results.find((_data) => _data.form === formType)?.id;
					console.log(drafts);
					viewForm(id, formType, false);
				} else {
					window.location.href = window.location.origin + '/link-expired';
				}
			} else {
				window.location.href = window.location.origin + '/link-expired';
			}
		};
		fetchform();
	}, []);

	return <></>;
};

export default injectIntl(connect(null, auth.actions)(OneTimeForm));
