import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { apiResetPassword } from "../../modules/services/api";
import { notification } from "antd";
import { getResponseErrorToString } from "../../utils/common-functions";

const initialValues = {
    password: "",
    confirmPassword: "",
};

const ResetPassword = (props) => {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const params = new URLSearchParams(props.location.search);
    const uidb64 = params.get('uidb64');
    const token = params.get('token');

    const history = useHistory();

    const LoginSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(50, "Maximum 50 symbols")
            .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
        confirmPassword: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(50, "Maximum 50 symbols")
            .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const onForgotPasswordSubmit = async (values, { setStatus, setSubmitting }) => {
        const body = {
            new_password1: values.password,
            new_password2: values.confirmPassword,
            uid: uidb64,
            token
        }
        try {
            const res = await apiResetPassword(body);
            // console.log(res)
            notification.success({ message: res?.data?.detail });
            setTimeout(() => {
                history.push({ pathname: `/auth/login` });
            }, 100);
        } catch (err) {
            console.log(err.response.data);
            if (err?.response?.data) {
                const errStr = getResponseErrorToString(err?.response?.data)
                console.log(errStr)
                notification.error({ message: errStr });
            } else if (setStatus) {
                setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_FIELD" }));
            }
        }
        if (setSubmitting) {
            setSubmitting(false);
        }
        disableLoading();
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, otherParams) => {
            enableLoading();
            onForgotPasswordSubmit(values, otherParams);
        },
    });

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    Reset Your Password
                </h3>
                <p className="text-muted font-weight-bold">
                    Enter new password
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                {formik.status ? (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {formik.status}
                        </div>
                    </div>
                ) : null}

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="New Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.password}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Confirm New Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("confirmPassword")}`}
                        name="confirmPassword"
                        {...formik.getFieldProps("confirmPassword")}
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.confirmPassword}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group">
                    <Link to="/auth/login" className="link-primary fs-6 fw-bolder">Back to login</Link>
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary font-weight-bold d-block  mx-auto rounded-0`}
                        style={loading ? { paddingRight: 30 } : null}
                    >
                        <span>Reset Password</span>
                        {loading && (<span className="ml-3 spinner spinner-white"></span>)}
                    </button>

                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl((ResetPassword));
