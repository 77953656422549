export const convertToPascalCase = (str) => {
    if (!str) {
        return;
    }
    let urlString = str.split("/").pop(),
        pascalString = urlString.replace(/\w\S*/g, (m) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase());
    return pascalString.replace(/-/g, " ");
};

const dollarLocale = Intl.NumberFormat('en-US', {
    // style: 'currency',
    // currency: 'INR',
    minimumFractionDigits: 2
});
export const getFormattedNumber = (field) => {
    // if (field?.value && (field?.type === 'float' || field?.type === 'integer')) {
    if (field?.value || field?.value === 0) {
        // console.log(field)
        if (isNaN(field.value)) { return field; }
        // if (!field.value.includes('.')) {
        field.value = '' + field?.value;
        field.value = dollarLocale.format(field?.value?.split(',').join(''));
            // value = value.toLocaleString(navigator.language, { minimumFractionDigits: 2 });
        // }
        // console.log('gone', field.value);
    }
    return field;
}

export const getResponseErrorToString = (errObj) => {
    // return errObj && Object.keys(errObj).map((e) => (`${e} : ${errObj[e]?.join()}`));
    return errObj && Object.keys(errObj).map((e) => errObj[e]?.join());
}