import React, { useEffect, useState, useRef } from 'react';
import { Badge } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { apiCancelDraftFormWithRemark, getPDF, getViewForm, submitActionForm } from '../../modules/services/api';
import ViewForm from "../forms/view-form";
import moment from "moment";
import { message, notification } from 'antd';
import { selectFormFieldData, selectUserData } from '../../../redux/selectors/form-selector';
import { resetTotalFormData } from '../../../redux/actions/action';
import { createStructuredSelector } from 'reselect';
import jsPDF from 'jspdf';
import { clearHeaderState, setHeaderTitle } from '../../../redux/reducers/header.reducer';
import './index.scss';

const ViewFormDetailsPage = ({ user, resetFormData }) => {
    const { parentType, formType, formId } = useParams();
    // console.log(parentType, formType, formId);
    const { params } = useLocation();
    const [remarks, setRemarks] = useState('');
    const [detailForm, setDetailForm] = useState();
    const [isShowPdfModal, setIsShowPdfModal] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [pdfLoading, setPdfLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);

    const url = parentType === "pending" ? "form/entries/action" : "form/entries";
    let controller = useRef()

    
    useEffect(() => {
        dispatch(setHeaderTitle(parentType));
        return () => {
            dispatch(clearHeaderState());
        };
    }, []);

    useEffect(() => {
        const getForm = async () => {
            const form = await getViewForm(formId, formType, url).then((res) => {
                // console.log(res)
                if (res?.status !== 200) {
                    notification.error({ message: res?.data?.detail })
                    return
                }
                return res?.data
            });
            form.type = formType //? setting the form type so that the formDetails will update in View-Form.jsx
            setDetailForm(form);
        }
        getForm();
    }, []);

    const getVariant = () => {
        let variant = "success";
        if (detailForm?.status === "pending") {
            variant = "danger";
        }
        return variant;
    };

    //
    const showCancelBtn = () => {
        if (detailForm?.status !== 'pending' || detailForm?.action_type === "execution") {
            return;
        }
        // console.log('showCancelBtn > detailForm -> ', detailForm)
        if ((user?.username === detailForm?.submitted_by?.username || user?.username === detailForm?.submitted_for?.username) && user?.is_staff) {
            return (
                <div className="btn btn-warning" onClick={() => { cancelDraftFormWithRemark(detailForm?.id, detailForm?.type || detailForm?.form); }}>Cancel</div>
            )
        }
    }

    const cancelDraftFormWithRemark = async (id, type) => {
        const remark = window.prompt(`Are you sure you want to cancel (${id})? \nRemarks`);
        console.log('id, type,remark =>', id, type, remark);
        if (remark === null) {
            console.log('User canceled the flow');
            return;
        }
        const url = 'form/entries';
        try {
            const payload = { status: "cancelled", note: remark };
            const res = await apiCancelDraftFormWithRemark(id, type, url, payload);
            console.log(res);
            if (res.status === 200) {
                // setIsModalVisible(false);
                // if (setCurrentPage) {
                //     setCurrentPage(1);
                // }
                // if (setReload) {
                //     setReload(true);
                // }
                notification.success({ message: res?.data?.detail || `Draft Cancelled Successfully` });
                setTimeout(() => {
                    history.goBack();
                }, 100);
            } else {
                // setIsModalVisible(false);
                notification.error({ message: res?.data?.detail });
            }

        } catch (error) {
            console.log('cancelDraftFormWithRemark error =>', error);
            message.error(`Something went wrong`);
        }
    }

    const showApproveRejectBtns = () => {
        if (detailForm?.action_type === "approval") {
            return (
                <div>
                    <button type="button" className="btn btn-danger btn-md mr-2 " onClick={(e) => handleSubmit(e, "reject")}>{" "}Reject</button>
                    <button type="button" className="btn btn-primary btn-md mr-2" onClick={(e) => handleSubmit(e, "approve")}>{" "}Approve</button>
                </div>
            )
        }
    }

    const showCompleteBtn = () => {
        if (detailForm?.action_type === "execution") {
            return (
                <button type="button" className="btn btn-success btn-md mr-2" onClick={(e) => handleSubmit(e, "complete")}>&nbsp;Complete</button>
            )
        }
    }

    const showCopyToNewEntryBtn = () => {
        // status === "pending,approved,draft" || status === "pending,approved" ||
        // submitted by and submitted for user can copy to new entry
        if (
            (detailForm?.id && detailForm?.action_type !== "approval" && detailForm?.action_type !== "execution") &&
            (detailForm?.submitted_by?.is_current_user || detailForm?.submitted_for?.is_current_user) && user?.is_staff
        ) {
            return (
                <button type="submit" className="btn btn-primary btn-md mr-2" onClick={() => copyToNewEntry(detailForm?.id, detailForm?.type || detailForm?.form, true)}>
                    &nbsp;Copy to new Entry
                </button>
            )
        }
    }

    const handleShowPdf = async (e)=>{
        setIsShowPdfModal(true)
        setPdfLoading(true)
        if(controller.current) controller.current.abort()
        controller.current = new AbortController()
        const url = await getPDF(detailForm?.id, controller.current)
        setPdfUrl(url)
        setPdfLoading(false)
    }

    const showPdfBtn = () => {
        // console.log(user, detailForm)
        if (
            detailForm?.type !== 'equipment-requisition' ||
            detailForm?.approvals.filter((layer) =>
                ['approved', 'complete'].includes(layer?.status)
            )?.length !== detailForm?.approvals?.length
        ) {
            return null;
        }

        const executed_by = detailForm?.executions[0]?.executed_by?.username;
        if (
            user?.username === 'admin' || user?.username === executed_by ||
            user?.roles?.includes(detailForm?.executions[0]?.action_role) ||
            user?.username === detailForm?.submitted_by?.username ||
            user?.username === detailForm?.submitted_for?.username
        ) {
            return (
                <div className="float-right">
                    <button
                        type="submit"
                        className="btn btn-primary btn-md ml-2"
                        onClick={(e) => handleShowPdf(e)}
                    >
                        Show Pdf
                    </button>
                </div>
            )
        }
    }

    const handleSubmit = async (e, action) => {
        e.preventDefault();
        const data = { action, remarks };
        const serialArray = detailForm?.serial_number ? detailForm.serial_number.split("#") : [];
        const serialNumber = serialArray[1] || "";
        const id = detailForm?.id;
        // const formType = detailForm?.type || detailForm?.form;
        // console.log(data, serialArray, serialNumber, id, formType);
        const response = await submitActionForm(id, formType, data);

        if (serialNumber) {
            data.serial_number = serialNumber;
        }

        if (response.isSuccess) {
            setRemarks('');
            // setReload(true);
            // setIsModalVisible(false);
            notification.success({message: `Successfully ${action}`})
            setTimeout(() => {
                history.goBack();
            }, 100);
        }
    };
    //
    const handleDismissPdfModal = () => {
        if (controller.current) controller.current.abort()
        setIsShowPdfModal(false);
    }

    const printDocument = (e) => {
        
        e.preventDefault();
        
        setPdfLoading(true)
        
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = pdfUrl;
        // the filename you want
        a.download = `Equipment Acknowledgement - ${detailForm?.id}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(pdfUrl);
        
        setPdfLoading(false)
    };

    const copyToNewEntry = async (id, type, isNewEntry) => {
        const url = parentType === "pending" ? "form/entries/action" : "form/entries";
        const formData = await getViewForm(id || formId, type || formType, url).then((data) => data.data);

        if (formData) {
            if (parentType === "draft" || isNewEntry) {
                if (!formId && !type) { resetFormData(); }

                //TODO: why they are pushing twice form-modal.jsx viewForm
                // history.push({
                //     pathname: `/forms`,
                // });

                history.push({
                    pathname: `/forms/${formType ? formType : formData.form}`,
                    params: { formInfo: formData, isNewEntry },
                });
            } else {
                // TODO: this will never go to else case
                console.log('viewForm if > else case > parentType, isNewEntry >', parentType, isNewEntry)
                formData.type = formType;
                setDetailForm(formData);
                // setIsModalVisible(true);
            }
        }
    };

    return (
        <>
            <div id='view-form-details-page'>
                {detailForm && (
                    <div className="wrapper-content">
                        <div className="d-flex w-100 header-section">
                            <div className="col-md-2">{detailForm?.serial_number}</div>
                            <div className="col-md-2 text-center">
                                <Badge className="status-label" variant="light">
                                    Status
                                </Badge>
                                <Badge className="status-label" variant={getVariant(detailForm?.status)} >
                                    {detailForm?.status}
                                </Badge>
                            </div>
                            <div className="col-md-4 text-center">
                                Submitted on{" "}{moment(detailForm?.created).format("MMMM Do YYYY, h:mm:ss a")}
                            </div>
                            <div className="col-md-4 text-right pr-10">
                                Submitted by {detailForm?.submitted_by?.first_name}{" "}
                                {detailForm?.submitted_by?.last_name}
                            </div>
                        </div>
                        <div className="wrapper-body">
                            <ViewForm
                                key={`view-${formId}`}
                                setRemarks={setRemarks}
                                remarks={remarks}
                                // isShowRemarks={status === "pending"}
                                isShowRemarks={detailForm?.action_type === "approval" || detailForm?.action_type === "execution"}
                                detailForm={detailForm}
                                getStatus={parentType}
                                handleDismissPdfModal={handleDismissPdfModal}
                                isShowPdfModal={isShowPdfModal}
                                printDocument={printDocument}
                                pdfLoading={pdfLoading}
                                pdfUrl={pdfUrl}
                            />
                            {detailForm?.note && <div className="d-flex mt-5 ml-6">Note:&nbsp;&nbsp;<b>{detailForm?.note}</b></div>}
                        </div>
                        <div className="wrapper-footer mt-5 ">
                            <div className="d-flex justify-content-end w-100">
                                <div className="mr-5">{showCancelBtn()}</div>
                                {showApproveRejectBtns()}
                                {showCompleteBtn()}
                                {showCopyToNewEntryBtn()}
                                {showPdfBtn()}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    user: selectUserData,
    formFields: selectFormFieldData,
});

const mapDispatchToProps = (dispatch) => ({
    resetFormData: (data) => dispatch(resetTotalFormData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewFormDetailsPage);