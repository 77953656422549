import React, { useState, useEffect, createRef, useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment";

import {
    makeStyles,
    OutlinedInput,
    Input,
    InputLabel,
    FormControl,
    Select,
    TextField,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    // IconButton,
    Divider,
    Link
} from "@material-ui/core";

import LinkIcon from '@material-ui/icons/Link';
import { nanoid } from "nanoid";

import SVG from "react-inlinesvg";
import ModalContainer from "../../components/Modal";

// import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import { createStructuredSelector } from "reselect";
import {
    selectFormData,
    selectFormFieldData,
    selectFormTableFieldData,
    selectFormTallyFieldData,
} from "../../../redux/selectors/form-selector.js";

import {
    updateFormData,
    updateFormFieldData,
    updateFormTableFieldData,
    updateFormTallyFieldData,
} from "../../../redux/actions/action.js";

import { DatePicker, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { uploadFormAttachments } from "../../modules/services/api.js";
// import { getComputeFieldType } from "../../modules/services/helpers.js";

import { getCompute } from "../../modules/services/api.js";

import PendingForms from "../activity/pending-forms.jsx";

import "antd/dist/antd.css";

import configData from "../../modules/services/config.json";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

import { truncate } from '../../modules/utils/utils';
import { cloneDeep } from "lodash-es";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    diabledInput:{
        "& .MuiInputBase-root.Mui-disabled": {
            color: "black",
            backgroundColor: "#e0e0e0"
        }
    }
}));

const getValueOrDefault = (_field) => {
    if(_field?.value == null && _field?.value == undefined){
        if(_field?.default == null && _field?.default == undefined){
            if((_field?.type === 'float' && _field?.optional) || _field?.type === 'readonly-float')
                return `${parseFloat("0")?.toFixed(_field?.decimal_placement)}`
            if(_field?.type === 'boolean')
                return false
            return ''
        }
        else{
            // if(_field?.type === 'float')
            //   return `${parseFloat(_field?.default)?.toFixed(2)}`

            // else
            if(_field?.type === 'boolean')
              return _field?.default

            return `${_field?.default}`
        }
    }
    else{
        if(_field?.type === 'readonly-float' || _field?.type === 'float'){
            if(!_field?.value){
                return `${parseFloat("0")?.toFixed(_field?.decimal_placement)}`
            }
            else{
                return `${parseFloat(_field.value).toFixed(_field?.decimal_placement)}`
            }
        }

        else if(_field?.type === 'boolean') return _field?.value

        return `${_field?.value}`
    }
  }

const FormInput = ({ key, formName, slipClass, field, hideHelp, error, type, rowKey, isReadOnly, fieldValue, tableFieldValue, tallyFieldValue,
    dataSource, setFieldValue, setTableFieldValue, setTallyFieldValue, formData, setDataSource, updateForm, changeEvent,
}) => {
    const classes = useStyles();
    const [intialState, setInitialState] = useState(false);
    const ref = createRef();
    const [labelWidth, setLabelWidth] = useState(0);
    const [fieldType, setFieldType] = useState(null);
    const [value, setValue] = useState("");
    const [props, setProps] = useState();
    const [fileList, setFileList] = useState([]);
    const [showReferenceModal, setShowReferenceModal] = useState();
    const [pageSize, setPageSize] = useState(10);

    const setInitialFieldValue = () => {
        if (!intialState && !(tableFieldValue && tallyFieldValue && fieldValue)) {
            let valObj = {};

            let val = getValueOrDefault(field)

            valObj.type = field?.type;
            valObj.extra = field?.extra;
            valObj.value = val //field?.value || "";
            valObj.label = field?.label
            valObj.optional = field?.optional || false;
            valObj.options = field?.options || []
            valObj.decimal_placement = field?.decimal_placement

            // if (field?.type && field.type === 'float') {
            //     valObj.value = decimal_format(valObj.value);
            // }

            if (type === "table-fields") {
                // let prevField = tableFieldValue ? tableFieldValue : {};

                // prevField[field?.slug] = valObj;
                let prevField = tableFieldValue ? tableFieldValue : dataSource || [{ key: nanoid() }],
                    newValObj = prevField.map((obj) => {
                        if (obj.key && JSON.stringify(rowKey) === JSON.stringify(obj.key)) {
                            obj[field?.slug] = valObj;
                        }
                        else if (!obj.key) {
                            obj.key = nanoid()
                        };
                        return obj;
                    });

                    newValObj.key = nanoid()

                setTableFieldValue(newValObj);

            } else if (type === "tally-fields") {
                let prevField = tallyFieldValue ? tallyFieldValue : {};

                prevField[field?.slug] = valObj;

                setValue(valObj.value);

                setTallyFieldValue(prevField);
            } else {
                let prevField = fieldValue ? fieldValue : {};

                prevField[field?.slug] = valObj;

                setFieldValue(prevField);
            }
            setInitialState(true);
        } else {
            // TODO: fix
            // console.log(tableFieldValue)
        }
    };

    setInitialFieldValue();

    const format = useCallback((fieldObj, slug) => {
        const formField = formData ? formData : null;
        const draftForm = cloneDeep(formField);
        /**Fields */
        const fields = fieldValue || {};

        if (formField && formField.fields && formField.fields.length > 0) {
            formField.fields.map((field) => {
                const newValue = {
                    label: field.label,
                    type: field.type,
                    extra: field.extra,
                    decimal_placement: field?.decimal_placement,
                    value: getValueOrDefault(fieldValue[field?.slug])  //(fieldValue && fieldValue[field.slug] && fieldValue[field.slug].value) ? fieldValue[field.slug].value : "",
                };
                return (fields[field.slug] = newValue);
            });
        }

        if (draftForm) {
            draftForm.fields = fields;
        }

        const tableInput = tableFieldValue || [];

        /**Table fields */
        const table_fields = tableInput && tableInput.length > 0 ? tableInput.map((fieldVal) => {
            if (formField && formField.table_fields && formField.table_fields.length > 0) {
                formField.table_fields.map((field) => {
                    const value = getValueOrDefault(fieldVal[field?.slug]);
                    let newValue = {
                        value: value ? value : (field?.type === "float") ? `${parseFloat("0")?.toFixed(field?.decimal_placement)}` : '' , //(fieldVal && fieldVal[field.slug] && fieldVal[field.slug].value) ? fieldVal[field.slug].value : field.default || "",
                        label: field?.label,
                        type: field?.type,
                        extra: field?.extra,
                        optional: field?.optional,
                        decimal_placement: field?.decimal_placement,
                        // options: field.options || [],
                    };

                    // if (field.type === "float") {
                    //     newValue.value = parseFloat(newValue.value);
                    // }

                    fieldVal[field?.slug] = newValue;

                    return field;
                });
            }
            return fieldVal;
        }) : [];

        if (draftForm) {
            draftForm.table_fields = table_fields;
        }
        /**Tally fields */
        const tally_fields = {};

        if (formField && formField.tally_fields && formField.tally_fields.length > 0) {
            formField.tally_fields.map((field) => {
                let newValue = {
                    label: field?.label,
                    type: field?.type,
                    decimal_placement: field?.decimal_placement,
                    extra: field?.extra,
                    value: getValueOrDefault(tallyFieldValue[field.slug]) //(tallyFieldValue && tallyFieldValue[field.slug] && tallyFieldValue[field.slug].value) ? tallyFieldValue[field.slug].value : "",
                };

                tally_fields[field.slug] = newValue;

                return field;
            });
        }
        if (draftForm) {
            draftForm.tally_fields = tally_fields;
        }
        return draftForm;
    },
        [formData, fieldValue, tableFieldValue, tallyFieldValue]
    );

    const getFormattedComputeForm = useCallback(
        (computeForm, dataForm, keyId) => {
            const forms = formData ? formData : dataForm; //!-------------------------------------------------------------------------------
            /**Data massaging */
            const tallyFieldObj = {};
            const tally_fields = forms && forms?.tally_fields.length > 0 ? forms.tally_fields.map((field) => {
                if (computeForm?.tally_fields && computeForm.tally_fields[field.slug]) {
                    field.value = computeForm.tally_fields[field.slug].value || "";

                    if (computeForm.tally_fields[field.slug]?.options) {
                        field.options = computeForm.tally_fields[field.slug].options;
                    }
                    if (computeForm.tally_fields[field.slug]?.type) {
                        field.type = computeForm.tally_fields[field.slug].type;
                    }
                } else {
                    // field.value = dataForm?.tally_fields[field.slug].value;
                    // FIXED: setting values if it has default value
                    field.value = dataForm?.tally_fields[field.slug].value || field.value || field.default;
                }
                if (computeForm?.tally_fields && computeForm.tally_fields[field.slug]?.extra && Object.keys(computeForm.tally_fields[field.slug]?.extra).length !== 0) {
                    field.type = computeForm.tally_fields[field.slug]?.extra?.type;
                    field.extra = computeForm.tally_fields[field.slug]?.extra;
                }

                tallyFieldObj[field.slug] = { ...field };
                // console.log(field)
                return field;
            }) : {};

            const fieldObj = {};
            const fields = forms && forms?.fields.length > 0 ? forms.fields.map((field) => {
                if (computeForm?.fields && computeForm.fields[field.slug]) {
                    field.value = computeForm?.fields[field.slug]?.value || fieldValue[field.slug]?.value || "";
                    if (computeForm.fields[field.slug]?.options) {
                        field.options = computeForm.fields[field.slug].options;
                    }
                    if (computeForm.fields[field.slug]?.type) {
                        field.type = computeForm.fields[field.slug].type;
                    }

                    if (field.slug === 'business-unit' && computeForm.fields[field.slug]?.value && tableFieldValue){

                        tableFieldValue.map((row)=>{
                            if (row['cost-to-bu']) {
                                if (computeForm.fields[field.slug]?.value !== row['cost-to-bu']?.value)
                                row['cost-to-bu'].value = computeForm.fields[field.slug]?.value
                            }
                            return row
                        })

                        setTableFieldValue(tableFieldValue) /////
                    }
                } else {
                    field.value = dataForm?.fields[field.slug].value;
                }

                fieldObj[field.slug] = { ...field };

                return field;
            }) : {};

            let table_fields = [],
                newDataSource = [];

            if (computeForm?.table_fields && computeForm?.table_fields.length > 0) {
                newDataSource = dataSource ? dataSource.map((data, index) => {
                    let fieldVal = computeForm.table_fields[index];

                    table_fields = forms && forms?.table_fields.length > 0 ? forms.table_fields.map((field) => {
                        let computeTableField = fieldVal[field.slug],
                            computeValue = computeTableField?.value //|| "";
                        if (JSON.stringify(fieldVal?.key) === JSON.stringify(data?.key)) {
                            if (computeTableField) {
                                if(computeTableField.optional === true || computeTableField.optional === false ){
                                    data[field.slug].optional = computeTableField.optional
                                }
                                if (field.type.includes("float") && parseFloat(computeValue))
                                    computeValue = parseFloat(computeValue).toFixed(field?.decimal_placement);

                                if (computeValue !== undefined) {
                                    data[field.slug].value = computeTableField.value //|| data[field.slug].value //{ value: computeTableField.value || "", };
                                }
                                if (computeTableField?.options) {
                                    let options = computeTableField.options.map(
                                        (option) => {
                                            if (!option.key) {
                                                option.key = data.key;
                                            }
                                            return option;
                                        }
                                    );

                                    if (options.length > 0) {
                                        if (data[field.slug]) {
                                            data[field.slug].options = options;
                                            field.options = [...options];
                                        } else {
                                            data[field.slug] = { options };
                                        }
                                    } else {
                                        data[field.slug].options = field?.options || [];
                                    }
                                }
                                if(computeTableField?.value !== undefined)
                                    field.value = computeTableField?.value; //field.value
                                if (computeTableField?.type) {
                                    field.type = computeTableField?.type;
                                    if (data[field.slug]) {
                                        data[field.slug].type = computeTableField?.type;
                                    }
                                }
                                if (computeTableField?.extra?.hasOwnProperty("url")) {
                                    field.extra = computeTableField.extra;
                                    if (data[field.slug]) {
                                        data[field.slug].extra = computeTableField?.extra;
                                    }
                                }
                            }
                        }
                        // else if (data[field.slug]) {
                        //     data[
                        //         field.slug
                        //     ].options = computeTableField
                        //         ? computeTableField.options
                        //         : field.options;
                        // }

                        // field = {
                        //     ...field,
                        //     ...computeTableField,
                        // };

                        return field;
                    }) : [];
                    return data;
                }) : [];
            }

            setTallyFieldValue(tallyFieldObj);
            setFieldValue(fieldObj);
            if (newDataSource.length > 0) setDataSource(newDataSource);

            const form = forms;
            if (tally_fields.length > 0) form.tally_fields = tally_fields;
            if (fields.length > 0) form.fields = fields;
            if (table_fields.length > 0) form.table_fields = table_fields;

            return form;
        },
        [formData, setDataSource, dataSource, fieldValue, setFieldValue, setTallyFieldValue, setTableFieldValue, tableFieldValue]
    );

    const computeFormData = useCallback(
        async (values, slug, fieldT) => {
            let data = format(values, slug);

            const endPoint = formData?.computer;

            const computeForm = endPoint ? await getCompute(data, endPoint) : null;
            if (computeForm) {
                const forms = getFormattedComputeForm(computeForm, data);

                // console.log('computeForm =>',computeForm)
                // console.log('forms =>',forms)
                // console.log('formData =>',formData)
                // console.log('data =>',data)
                // console.log('updateForm =>',updateForm)
                // FIXED: setting values if it has default value
                // if(forms?.tally_fields) {
                //     forms.tally_fields = forms?.tally_fields.map((field) => {
                //         if (field?.default && !field?.value) {
                //             // console.log(field);
                //             field.value = field.default;
                //             return field;
                //         }
                //         return field;
                //     });
                // }

                // if (fieldT === "table-fields") {
                //     setTableFieldValue(data.table_fields)
                // };

                if (updateForm) {
                    updateForm(forms);
                }
            }
        },
        [format, updateForm, formData, getFormattedComputeForm]
    );

    const handleChange = useCallback(
        async (value, field, type) => {
             
            field.value = value;

            let values = {},
                valObj = {};

            valObj.type = field?.type;
            valObj.label = field?.label;
            valObj.extra = field?.extra;
            valObj.value = value || "";
            valObj.optional = field?.optional || false;
            valObj.decimal_placement = field?.decimal_placement;

            if (type === "table-fields") {
                let prevField = tableFieldValue || dataSource,
                    newValObj = prevField.map((obj) => {
                        if (JSON.stringify(rowKey) === JSON.stringify(obj.key)) obj[field.slug] = valObj;
                        return obj;
                    });

                values = newValObj;
            } else if (type === "tally-fields") {
                let prevField = tallyFieldValue ? tallyFieldValue : {};

                prevField[field.slug] = valObj;

                values = prevField;

                if (tableFieldValue && field.slug === "currency"){
                    tableFieldValue.map((row)=>{
                        if (row['currency']){
                            row['currency'].value = field.value
                        }
                        return row
                    });
                    setTableFieldValue(tableFieldValue)
                }
                setTallyFieldValue(prevField);
            } else {
                let prevField = fieldValue ? fieldValue : {};

                prevField[field.slug] = valObj;

                values = prevField;

                if (field.slug === "submitted-for" && tableFieldValue ){
                    tableFieldValue.map((row)=>{
                        if(row['staff-name'])
                        row['staff-name'].value = field.value;
                        return row
                    })
                    setTableFieldValue(tableFieldValue)
                }

                setFieldValue(prevField);
            }

            if (
                computeFormData &&
                ["dropdown", "checkbox", "date", "link"].includes(fieldType)
            )
                computeFormData(values, field.slug, type);

            if (changeEvent) {
                // console.log(value, field, type)
                changeEvent(value, field, type);
            }
        
        }, [rowKey, fieldType, changeEvent, fieldValue, dataSource, setTallyFieldValue, setFieldValue, setTableFieldValue, tableFieldValue, tallyFieldValue, computeFormData]);

    const handleChangeEvent = useCallback(
        (eventOrValue, momentDate) => {
            // console.log({ eventOrValue }, { momentDate })
            // console.log(eventOrValue?.target?.name,eventOrValue?.target?.value, field, formData)
            let value = ["date", "link", "file"].includes(fieldType) ? eventOrValue : eventOrValue?.target?.value || "";

            if (fieldType === "checkbox") {
                value = !value;
            } else if (fieldType === "date" && value) {
                // value = momentDate;
                if (formName === 'replacement-leave-requisition') {
                    value = momentDate.format('YYYY-MM-DD');
                }
            }

            if (/*fieldType === "number" &&*/ field?.type.includes('float') && field?.optional && !value){
                value = `${parseFloat("0")?.toFixed(field?.decimal_placement)}`
            }

            // if (field.slug === "percentage-incident-cost" || field.slug === "percentage-of-statutory-contribution" || field.slug === "percentage-commission") {
            //     value = value.replace(/[^0-9]/g, '');
            // }

            // console.log(value,field,type,rowKey)
            handleChange(value, field, type, rowKey);
            setValue(value);
        }, [field, fieldType, handleChange, rowKey, type, formName]);

    const getProps = useCallback(async () => {
        let rowValue = -1,
            submittedForUserName = fieldValue && fieldValue["submitted-for"] && fieldValue["submitted-for"].value ? fieldValue["submitted-for"].value : "test";

        if (type === "table-fields") {
            rowValue = 1;
        }
        else if (type === "tally-fields") {
            rowValue = -2;
        }

        let data = {
            row: rowValue,
            field: "attachment",
            formName,
            change: handleChange,
            submitted_by: submittedForUserName,
        };

        let uplod = await uploadFormAttachments(
            data,
            setFileList,
            handleChange,
            field
        ).then((res) => res);

        setProps(uplod);
    }, [type, formName, handleChange, fieldValue, field]);

    // const findStateValue = useCallback(() => {
    //   let valueObj = null;

    //   if(type === 'table-fields') {

    //     valueObj = tableFieldValue ? tableFieldValue.find(obj => obj.key === rowKey) : {};

    //   }else if(type === 'tally-fields') valueObj = tallyFieldValue;
    //   else valueObj = fieldValue;

    //   return valueObj && valueObj[field.slug] && valueObj[field.slug].value ? valueObj[field.slug].value : '';
    // },[field,rowKey, type, fieldValue, tableFieldValue, tallyFieldValue, ])

    useEffect(() => {
        // if (ref?.current?.offsetWidth) setLabelWidth(ref?.current?.offsetWidth);

        const fieldConfig = configData.FORM_TYPE || [];

        const fieldObj = fieldConfig.find((configObj) => {
            return configObj.allowed_types.includes(field?.type);
        });

        setFieldType(fieldObj?.type || "");

        if (fieldType === "file" && !props) setProps(getProps());

        if (field?.value) {
            if (typeof field.value === "object") {
                setValue(field.value.value);
            } else setValue(field.value);
        }

        if (fieldValue && fieldValue[field?.slug]?.value && !type) {
            let valueOfField = fieldValue[field.slug].value;

            if (typeof valueOfField === "object") {
                setValue(valueOfField);
            } else {
                setValue(valueOfField)
            };
        } else if (tallyFieldValue && tallyFieldValue[field?.slug]?.value && type === "tally_fields") {
            let valueOfField = tallyFieldValue[field.slug]?.value;
            if (typeof valueOfField === "object") {
                setValue(valueOfField);
            } else {
                setValue(valueOfField)
            };
        }

        if (tableFieldValue?.length > 0) {
            let fieldObj = tableFieldValue.find((fieldVal) => JSON.stringify(fieldVal.key) === JSON.stringify(rowKey));

            if (fieldObj && fieldObj?.options?.length) {
                field.options = fieldObj.options;
            }
        }
        // ref,
    }, [field, type, rowKey, tableFieldValue, setLabelWidth, setFieldType, setValue,
        props, setProps, fieldType, getProps, formName, fieldValue, setFieldValue, tallyFieldValue]);

    const handleChangeCheck = (event) => {
        // let newValue =
        //     fieldValue && fieldValue[field.slug] && fieldValue[field.slug].value
        //         ? false
        //         : true;

        // New Code
        let newValue;
        if (type === 'table-fields') {
            newValue = field && field?.slug && field?.value ? false : true;
        } else {
            newValue = fieldValue && fieldValue[field.slug] && fieldValue[field.slug].value ? false : true;
        }
        // New Code End
        // console.log({field},{newValue})
        handleChange(newValue, field, type, rowKey);

        setValue(newValue);
    };

    const showLinkView = (e) => {
        setShowReferenceModal(true);
    };
    // const removeReferenceValue = (e) => {
    //     handleChange(null, field, type, rowKey);
    // };
    const handleFileUpload = (info) => {
        // if (info.file.status !== "uploading") {
        //     // console.log(info.file, info.fileList);
        // }

        if (info) {
            if (info.file.status !== "removed") {
                let fileList = [...info.fileList];
                // 1. Read from response and show file link
                fileList = fileList.map((file) => {
                    if (file.response) {
                        // Component will show file.url as link
                        file.url = file.response.file;

                        file.name = file.response && file.response.file
                            ? //eslint-disable-next-line
                            truncate(file.response.file, 15)
                            // file.response.file.replace(/^.*[\\\/]/, "")
                            : "";
                    }
                    return file;
                });

                handleChangeEvent(fileList[0].url, field);

                setFileList(fileList);

                return fileList;
            } else {
                handleChange("", field, type);

                setValue("");
                setFileList("");

            }
            if (info.file.status === "done") {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === "removed") {
                message.success(`${info.file.name} file removed successfully`);
            } else if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        } else {
            handleChange("", field, type);

            setValue("");
            setFileList("");

        }


    };

    const handleCancel = () => {
        setShowReferenceModal(false);
    };

    const getError = (slug) => {
        let obj = null;
        if (type === "table-fields") {
            // let valObj = tableFieldValue[0];
            // console.log(field)
            obj = field || {};
            // && obj.key === valObj.key
            // if (valObj && valObj[slug] && valObj[slug].value) {
            //     obj.value = valObj[slug].value || "";
            // }
        } else if (type === "tally-fields") {
            obj = tallyFieldValue ? tallyFieldValue[slug] : null;
        } else {
            obj = fieldValue && fieldValue[slug] ? fieldValue[slug] : null;
        }

        if (obj && typeof obj === "object" && !["break", "hidden", "boolean"].includes(obj?.type)) {
            if (!obj?.optional && !obj?.value && !isReadOnly) {
                return "Field is required!";
            } else if (obj?.value && obj?.type === 'integer' && !(/^-?\d+$/.test(obj?.value)) && !isReadOnly) {
                return "Value must be integer";
            }
        }
    };
    const isValidHttpUrl = (str) => {
        let regEx =
          /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$/gm;
        return regEx.test(str);
    };

    const getInputElement = (fieldData) => (
        <>
            {fieldType === 'number' || field?.type === 'readonly-float' ? (
                /** Developer Note:
                * developer note for field.type === text
                * there is no way to make field read only or disabled from backend side
                * they are sending field.type = `text` and frontend is making it disabled with that condition
                * now we have another field type NumberFormat which we also wants to disable (sometimes) so checked with `isNaN`
                */
                <NumberFormat
                    id={key}
                    required={!fieldData?.optional && !isReadOnly}
                    InputLabelProps={{required : false}}
                    error={formData?.error && !!getError(fieldData?.slug) ? true : false}
                    disabled={fieldType === "hidden" || isReadOnly || field?.type?.startsWith('readonly') || field?.readOnly ? true : false}
                    label={hideHelp ? "" : fieldData?.label || ""}
                    className={`${error && !!getError(fieldData?.slug) ? "error" : ""} ${classes.textField} ${classes.diabledInput} text-fields`}
                    type="text"
                    name={fieldData?.slug}
                    helperText={formData?.error ? getError(fieldData?.slug) : hideHelp ? "" : fieldData?.help}
                    // defaultValue={fieldData?.default}
                    value={value || getValueOrDefault(fieldData)
                          /*value || (fieldData?.default !== null ? `${fieldData?.default}` : "")*/}
                    onValueChange={({ value: v, floatValue, formattedValue}) => {
                        handleChangeEvent({ target: { name: 'name', value: floatValue } })
                    }}
                    // isNumericString={true}
                    onBlur={() => computeFormData(value)}
                    variant="outlined"
                    decimalScale={field?.type === 'float' || field?.type === 'readonly-float' ? field?.decimal_placement : 0}
                    // mask={mask}
                    customInput={TextField}
                    fixedDecimalScale={field?.type === 'float' || field?.type === 'readonly-float'}
                    thousandsGroupStyle="thousand"
                    fullWidth={true}
                    // prefix={'$'}
                    // format={format || null}
                    thousandSeparator={','}
               />
            ) : (fieldType !== "file") ? (
                <TextField
                    id={key}
                    required={!fieldData.optional && !isReadOnly}
                    error={formData?.error && !!getError(fieldData?.slug) ? true : false}
                    disabled={fieldType === "hidden" || isReadOnly || field.type === 'text' || field.readOnly ? true : false}
                    label={hideHelp ? "" : fieldData?.label || ""}
                    className={fieldType === "checkbox" ? ` ${error && !!getError(fieldData.slug) ? "error" : ""} ${classes.textField} ${classes.diabledInput} text-fields top-10` : ` ${error && !!getError(fieldData.slug) ? "error" : ""} ${classes.textField} ${classes.diabledInput} text-fields`}
                    type={fieldType !== "checkbox" ? fieldType : ""}
                    // number formatting
                    // type={fieldType !== "checkbox" ? fieldType === 'number' ? 'text' : fieldType : ""}
                    name={fieldData.slug}
                    helperText={formData?.error ? getError(fieldData.slug) : hideHelp ? "" : fieldData.help}
                    value={ /*value ||*/ getValueOrDefault(fieldData) /*value || (fieldData?.default !== null ? `${fieldData?.default}` : "")*/}
                    onChange={(e) => handleChangeEvent(e)}
                    onBlur={(e) => computeFormData(value)}
                    variant="outlined"
                    checked={fieldType === "checkbox" && getValueOrDefault(fieldData)}//{value ? "checked" : ""}
                    InputLabelProps={["file"].includes(fieldType) ? { shrink: true , required : false } : fieldType === "checkbox" ? { shrink: false , required : false } : {className:"" , required : false}}
                    InputProps={{
                        endAdornment: (
                            isValidHttpUrl(value) ? (
                                <>
                                    <Divider style={{height: 28, margin: 4}} orientation="vertical" />
                                    <Link
                                        // className={classes.iconButton}
                                        style={{paddingLeft: 10}}
                                        // onClick={(e) => isValidHttpUrl(value) ? '' : e.preventDefault()}
                                        // color={isValidHttpUrl(value) ? 'primary' : 'secondary'}
                                        color={'primary'}
                                        href={value}
                                        target="_blank"
                                    >
                                        <LinkIcon />
                                    </Link>
                                </>
                            ) : (null)
                        ),
                    }}
                />
            ) : (
                <div className={`custom-file ${slipClass}`}>
                    {type !== "table-fields" ? (
                        <TextField
                            className={`${classes.textField} text-fields`}
                            id={key}
                            required={!field.optional && !isReadOnly}
                            error={formData?.error && !field.optional && !value && !isReadOnly ? true : false}
                            name={fieldData.slug}
                            type="text"
                            variant="outlined"
                            disabled={isReadOnly}
                            value={""}
                            onChange={(e) => handleChangeEvent(value)}
                            helperText={hideHelp ? "" : fieldData.errorMessage || fieldData.help}
                            label={fieldData?.label || ""}
                            InputLabelProps={{ shrink: true , required: false }}
                        ></TextField>
                    ) : null}
                    {!isReadOnly && !((field.value || value) && type) ? (
                        <>
                            <Upload
                                {...props}
                                onChange={handleFileUpload}
                                // disabled={fileList.length > 0}
                                multiple={false}
                                maxCount={1}
                                // onChange={() => console.log(field.value)}
                                fileList={fileList.length > 0 ? fileList : value ? [{
                                    //   name: value?.replace(
                                    //       //eslint-disable-next-line
                                    //       /^.*[\\\/]/,
                                    //       ""
                                    //   ),
                                    name: value && truncate(value, 15),
                                    url: value,
                                }] : []
                                }
                                className={`${fileList.length > 0 ? "hide-button upload-button" : "upload-button"}`}
                            >
                                {" "}
                                <Button icon={<UploadOutlined />}>
                                    Click to Upload
                                </Button>{" "}
                            </Upload>
                        </>
                    ) : (
                        <div style={{ position: isReadOnly ? 'unset' : 'relative' }}>
                            <a className="file-view" rel="noopener noreferrer" href={field.value || value} target="_blank">
                                {//eslint-disable-next-line
                                    // value?.replace(/^.*[\\\/]/, "")
                                    field?.value && truncate(field?.value, 15)
                                }
                            </a>
                            {!isReadOnly && <span className="svg-icon svg-icon-md svg-icon-danger" onClick={(e) => handleFileUpload()} style={{ position: 'absolute', top: 17, right: -25 }}>
                                <SVG height={16} width={16} src={"/media/svg/icons/General/Trash.svg"} />
                            </span>}
                        </div>
                    )}
                </div>
            )}
        </>
    );

    return (
        <>
            {fieldType !== "hidden" && field ? (
                <div className={`form-input ${type} ${field.type} ${(!value && !field?.value && !field?.default && !field.optional && !isReadOnly) ? (field.type === 'text' ? "disabled" : "required") : field.type === 'text' ? "disabled" : ""}`}>
                     {/* data-testid={JSON.stringify(field)} */}
                    {fieldType !== "break" ? (fieldType === "dropdown" && !isReadOnly ? (
                        <FormControl variant="outlined" className={`${classes.formControl} ${classes.diabledInput} custom-fields drop-down`} error={formData?.error && !!getError(field.slug) ? true : false} >
                            {hideHelp ? ("") : (<InputLabel ref={ref} htmlFor="outlined-age-simple">{field?.label || ""} </InputLabel>)}
                            <Select
                                disabled={field?.disabled || false}
                                className="form-field-select"
                                value= {getValueOrDefault(field)}// {field.value || value || field.default || ""}
                                variant='outlined'
                                label={field?.label || ""}
                                required={!field.optional && !isReadOnly}
                                InputLabelProps={{required : false}}
                                error={formData?.error && !!getError(field.slug) ? true : false}
                                onChange={(e) => handleChangeEvent(e)}
                                // InputLabelProps={{ shrink: true }}
                                input={type === 'table-fields' ? <OutlinedInput labelWidth={labelWidth} name={field.slug} id="outlined-age-simple" /> : null}
                            >
                                {/* <MenuItem value=" ">None</MenuItem> */}
                                {/* && optionData.key === rowKey  ( used in below drop down condition)*/}
                                {field.options && field.options.length > 0 ?
                                    field.options.map((optionData, index) => (optionData.key) || !optionData.key ? (
                                        <MenuItem key={`${key}-${optionData.value}`} value={optionData.value} >
                                            {optionData.label}
                                        </MenuItem>) :
                                        null) :
                                    null
                                }
                            </Select>
                            <FormHelperText>
                                {formData?.error ? getError(field.slug) : hideHelp ? "" : field.help}
                            </FormHelperText>
                        </FormControl>
                    ) : fieldType === "date" ? (
                        <div className="date-row">
                            {/* {isReadOnly ? <label htmlFor="">{field.label}</label> : ""} */}
                            <label htmlFor="">{field?.label}</label>
                            {/* {console.log(!field.optional && !isReadOnly)} */}
                            <DatePicker
                                value={field?.value ? (moment.isMoment(field?.value) ? field?.value : moment(field?.value)) : ""}
                                // defaultValue={value ? moment(value) : ""}
                                className={`${formData?.error && !!getError(field.slug) ? "error" : ""} ${classes.textField} date-field top-5 ${error && !!getError(field.slug) ? "error" : ""}`}
                                placeholder={!type ? field.label : ""}
                                disabled={isReadOnly}
                                required={!field.optional && !isReadOnly}
                                InputLabelProps={{required : false}}
                                // format={"DD-MM-YYYY"}
                                error={formData?.error && !!getError(field.slug) ? true : false}
                                onChange={(date, dateString) => handleChangeEvent(dateString, date)}
                            />
                            <FormHelperText className={`pl-8 ${formData?.error && !!getError(field.slug) ? "error-text" : ""}`} >
                                {formData?.error ? getError(field.slug) : hideHelp ? "" : field.help}
                            </FormHelperText>
                        </div>
                    ) : fieldType === "checkbox" ? (
                        <>
                            <TextField
                                id={key}
                                required={!field.optional && !isReadOnly}
                                error={formData?.error && !!getError(field.slug) ? true : false}
                                disabled
                                value={""}
                                // label={hideHelp ? "" : field?.label || ""}
                                className={` ${formData?.error && !!getError(field.slug) ? "error" : ""} ${classes.textField} text-fields`}
                                name={field.slug}
                                helperText={formData?.error ? getError(field.slug) : hideHelp ? "" : field.help}
                                variant="outlined"
                                InputLabelProps={{ shrink: false , required : false }}
                            />
                            <FormControlLabel
                                className="checkbox-label"
                                control={
                                    <Checkbox
                                        // checked={
                                        //     (fieldValue &&
                                        //         fieldValue[field.slug] &&
                                        //         fieldValue[field.slug]
                                        //             .value) ||
                                        //     (isReadOnly && value)
                                        //         ? true
                                        //         : false
                                        // }
                                        checked={type === 'table-fields' ? ((field && field.slug && field.value) || (isReadOnly && value) ? true : false) : (fieldValue && fieldValue[field.slug] && fieldValue[field.slug].value) || (isReadOnly && value) ? true : false}
                                        disabled={isReadOnly}
                                        onChange={(e) => handleChangeCheck(e)}
                                        name={field.slug}
                                        color="secondary"
                                    />
                                }
                                label={type !== 'table-fields' ? field.label : ''}
                            />
                        </>
                    ) : fieldType === "link" ? (
                        <>
                            <FormControl className={`${classes.formControl} ${error && !!getError(field.slug) ? "error" : ""} custom-fields reference-field`} error={error && !!getError(field.slug) ? true : false} >
                                {(type !== 'table-fields' && field?.label) ? (<InputLabel>{field?.label} </InputLabel>) : null}
                                <Input
                                    type={fieldType}
                                    value={getValueOrDefault(field) ? getValueOrDefault(field).split(",")[1] ? getValueOrDefault(field).split(",")[1] : getValueOrDefault(field) : ""}
                                    // defaultValue={value ? value.split(",")[1] ? value.split(",")[1] : value : ""}
                                    title={getValueOrDefault(field) ? getValueOrDefault(field).split(",")[1] ? getValueOrDefault(field).split(",")[1] : getValueOrDefault(field) : ""}
                                    disabled
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {/* <IconButton
                                                aria-label="show reference form"
                                                onClick={(e) => showLinkView(e)}
                                                edge="end"
                                            >
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")} />
                                            </IconButton> */}
                                            <button type='button' onClick={(e) => showLinkView(e)} >
                                                <span className="svg-icon svg-icon-md svg-icon-dark">
                                                    <SVG height={16} width={16} src={"/media/svg/icons/General/Attachment2.svg"} />
                                                </span>
                                            </button>
                                            <button type='button' onClick={(e) => handleChangeEvent(null)} >
                                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                                    <SVG height={16} width={16} src={"/media/svg/icons/General/Trash.svg"} />
                                                </span>
                                            </button>
                                        </InputAdornment>
                                    }
                                // labelWidth={70}
                                />
                                <FormHelperText>
                                    {error ? getError(field.slug) : hideHelp ? "" : field.help}
                                </FormHelperText>
                            </FormControl>
                            <ModalContainer
                                isOpen={showReferenceModal}
                                onClose={handleCancel}
                                modalBody={
                                    <PendingForms
                                        handleChange={handleChangeEvent}
                                        hideModal={setShowReferenceModal}
                                        linkUrl={field?.extra?.url || ""}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        initialColumns={[]}
                                        showSelect={true}
                                        isReference={true}
                                    />
                                }
                            />
                        </>
                    ) : (getInputElement(field))) : null}{" "}
                </div>
            ) : null}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    formData: selectFormData,
    fieldValue: selectFormFieldData,
    tableFieldValue: selectFormTableFieldData,
    tallyFieldValue: selectFormTallyFieldData,
});

const mapDispatchToProps = (dispatch) => ({
    setFormData: (data) => dispatch(updateFormData(data)),
    setFieldValue: (data) => dispatch(updateFormFieldData(data)),
    setTableFieldValue: (data) => dispatch(updateFormTableFieldData(data)),
    setTallyFieldValue: (data) => dispatch(updateFormTallyFieldData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormInput);
