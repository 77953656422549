import React, { useEffect, useState } from 'react';
import { getDashboardQuotes } from '../../modules/services/api.js';
// import { quotes } from "./quotes.js";

const RandomQuote = ({ className }) => {
	const [quote, setQuote] = useState('');

	useEffect(() => {
		const randomQuote = async () => {
			const quotes = await getDashboardQuotes();

			const randomNumber = quotes && Math.floor(Math.random() * quotes.length);
			randomNumber && setQuote(quotes[randomNumber]);
		};

		randomQuote();
	}, []);

	return quote ? (
		<div id='container' className={`form ${className}`}>
			<div className='fadeIn' key={Math.random()}>
				<h4
					style={{
						wordWrap: 'break-word',
						color: quote.color,
						textAlign: 'center',
					}}>
					"{quote.quote}"
				</h4>
			</div>
		</div>
	) : (
		<></>
	);
};

export default RandomQuote;
