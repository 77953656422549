import React from 'react';

const OnetimeLayout = ({ children }) => {
	return (
		<div>
			<div className='mx-30 py-6 d-flex justify-content-center my-5 rounded aside-menu'>
				<img
					height='80px'
					src={`${process.env.REACT_APP_APP_LOGO_URL ||
						process.env.REACT_APP_APP_ICON_URL}`}
					alt='Logo'
				/>
			</div>
			<div className='px-30 pb-15'>{children}</div>
		</div>
	);
};

export default OnetimeLayout;
