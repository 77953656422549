/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector, connect } from 'react-redux';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { AuthPage } from '../app/pages/home/home-page.jsx';
import NotFound from './modules/Errors/not-found';
import LinkExpired from './modules/Errors/link-expired';
import { getAuthToken } from '../app/modules/services/token.js';
import * as auth from '../redux/reducers/user.reducer';
import { put } from 'redux-saga/effects';
import OnetimeLayout from '../_metronic/layout/components/OnetimeLayout';

const Routes = (props) => {
	const { isAuthorized } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.user != null && auth?.user?.is_staff,
			authToken: auth.authToken,
		}),
		shallowEqual
	);

	const token = getAuthToken();

	useEffect(() => {
		getAuthToken()
			.then((res) => {
				// console.log(res);
				if (res) {
					const iterator = getUser();
					iterator.next();
				}
			})
			.catch((err) => {
				console.log(err);
			});

		// dispatch(props.requestUser());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getUser = function*() {
		yield put(props.requestUser());
	};

	return (
		<Switch>
			<Route path='/link-expired' component={LinkExpired} />

			{!(isAuthorized && token) &&
			!window.location.pathname.match('/auth/oneTime/') ? (
				/*Render auth page when user at `/auth` and not authorized.*/
				<Route>
					<AuthPage />
				</Route>
			) : (
				!window.location.pathname.match('/auth/oneTime/') && (
					/*Otherwise redirect to root page (`/`)*/
					<Redirect from='/auth' to='/' />
				)
			)}

			<Route path='/error' component={NotFound} />

			{/* <Route path="/logout" component={Logout} /> */}

			{!isAuthorized && !window.location.pathname.match('/auth/oneTime/') ? (
				/*Redirect to `/auth` when user is not authorized*/
				<Redirect to='/auth/login' />
			) : !window.location.pathname.match('/auth/oneTime/') ? (
				<Layout>
					<BasePage />
				</Layout>
			) : (
				// <OneTimeForm />
				<OnetimeLayout>
					<BasePage />
				</OnetimeLayout>
			)}
		</Switch>
	);
};
export default connect(null, auth.actions)(Routes);
