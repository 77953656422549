import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment";

import * as Sentry from "@sentry/react";

import { createStructuredSelector } from "reselect";
import {
    selectFormData,
    selectForms,
} from "../../../redux/selectors/form-selector.js";

import { Table, List, Card, Button } from "antd";

import ModalContainer from "../../components/Modal";

import { Badge } from "react-bootstrap";

import { LayoutSplashScreen } from "../../../_metronic/layout";

import { updateSlipData, getViewForm } from "../../modules/services/api.js";
import { resetTotalFormData } from "../../../redux/actions/action.js";

import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import FormInput from "./form-fields.jsx";

import Form from "./form";
import { convertToPascalCase, getFormattedNumber } from "../../utils/common-functions.js";
import EquipmentPDF from "./equipment-pdf.jsx";
import MagicLinkTable from "../../components/Magic-link-table.jsx";
import store from '../../../redux/store';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ViewForm = ({
    detailForm,
    remarks,
    setRemarks,
    resetFormData,
    isShowRemarks,
    formData,
    totalFormValue,
    getStatus,
    pdfLoading,
    pdfUrl,
    // pdfrelated starts
    handleDismissPdfModal, isShowPdfModal, printDocument
    // pdfrelated ends
}) => {
    const [slips, setSlips] = useState([]);
    const [approvedSlips, setApprovedSlips] = useState([]);
    const [completedSlips, setCompletedSlips] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isShowSlip, setIsShowSlip] = useState(false);
    const [slipData, setSlipData] = useState(null);
    const [inputData, setInputData] = useState({});
    const [showSuccessToaster, setShowSuccessToaster] = useState(false);
    const [showToaster, setShowToaster] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [formDetail, setFormDetail] = useState(detailForm);
    const [expandedKeys, setExpandedKeys] = useState({})

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShowToaster(false);
        setShowSuccessToaster(false);
    };

    const validateFormat = useCallback(
        (form) => {
            const draftForm = {};

            /**Fields */

            draftForm.fields = totalFormValue.fields;

            draftForm.layout =
                form?.fields?.length > 0
                    ? form.fields.map((field) => field.slug)
                    : [];

            /**Table fields */

            draftForm.table_fields = totalFormValue.tableFields;

            draftForm.table_layout =
                form?.table_fields?.length > 0
                    ? form.table_fields.map((field) => field.slug)
                    : [];

            /**Tally fields */
            draftForm.tally_fields = totalFormValue.tallyFields;

            draftForm.tally_layout =
                form?.tally_fields?.length > 0
                    ? form.tally_fields.map((field) => field.slug)
                    : [];
            
            form?.tally_fields?.length > 0
            && form.tally_fields.map((field) => {
                if(draftForm.tally_fields[field?.slug] && !draftForm.tally_fields[field?.slug]?.value) {
                    draftForm.tally_fields[field?.slug].value = field?.value
                }
                return field
            })

            return draftForm;
        },
        [totalFormValue]
    );

    const setForms = async () => {
        let id = formDetail?.id || "",
            formType = formDetail?.type || "",
            actionType = formDetail?.action_type || "",
            formUrl = formDetail.submitted_by.is_current_user || formDetail.submitted_for.is_current_user ? 'form/entries' : 'form/entries/action'
        formData =
            id && formType
                ? await getViewForm(id, formType, formUrl).then(
                    (data) => data.data
                )
                : formDetail;

        if (formData) {
            formData.type = formType;
            formData.action_type = actionType;

            setFormDetail(formData);
        }
    };

    const validateRequiredFields = (form, slipId)=>{

        console.log(form)
        console.log(slipId)

        try{
            const fieldsError = form && form?.fields && Object.keys(form?.fields).find((field)=>{
                if (!form?.fields[field]?.optional && !form?.fields[field]?.value && !['break', 'hidden'].includes(form?.fields[field]?.type) && field){
                    throw new Error(`Slip: ${slipId} - There is an empty field: ${field}`)
                }
            })
            
            const tableFieldsError = form && form?.table_fields && form?.table_fields.find((row, index)=>{
                return form?.table_layout.find((field)=>{
                    if (!row[field]?.optional && !row[field]?.value && !['break', 'hidden'].includes(row[field]?.type) && field){
                        throw new Error(`Slip: ${slipId} - There is an empty table field: ${field} - row: ${index} - value: ${row[field]?.value} - optional: ${row[field]?.optional} - Full Data: ${row}`)
                    }
                })
            })
    
            return false

        } catch(e) {
            const state = store.getState();
            const user = state.auth.user
            Sentry.setUser({ email: user.email });
            Sentry.captureException(new Error(e))
            Sentry.setUser(null);
            return true
        }

    }

    const handleOk = async (slipId) => {
        // console.log(slipId, formData.table_fields.map((obj) => obj.label), approvedSlips, completedSlips, slipData, inputData, formDetail)
        let slipUrl;
        if (formDetail?.action_type && formDetail.action_type === 'approval') {
            slipUrl = 'amendment';
        } else if (formDetail?.action_type && formDetail.action_type === 'execution') {
            slipUrl = 'execution';
        }

        if (slipUrl) {
            let data = validateFormat(formData)

            // TODO this seems to produce errors, check further why this is happening
            // let hasError = validateRequiredFields(data, slipId)
            // let response = !hasError ? await updateSlipData(slipId, data, slipUrl) : {isSuccess: false, result: 'Please fill all required fields!'};
            let response = await updateSlipData(slipId, data, slipUrl)
            // let response = await updateSlipData(slipId, data, slipUrl)

            if (response?.isSuccess) {
                setLoading(true);
                setForms();

                setShowSuccessToaster(true);

                setIsShowSlip(false);

                setLoading(false);
            } else {
                if (
                    typeof response === "string" ||
                    typeof response?.result === "string"
                )
                    setErrorMessage(response.result || response);

                // setIsShowSlip(false);
                setShowToaster(true);
                setForms();
            }
        }
    };

    const handleCancel = () => {
        setForms();
        setIsShowSlip(false);
    };

    const remarkField = {
        type: "text-area",
        value: remarks,
        label: "Action remarks",
        optional: true,
        help: "Optionally add some remarks to the approval/execution",
    };

    const handleChange = (value) => {
        setRemarks(value);
    };

    const getColumns = () => {
        let tableFields = formDetail?.data?.table_layout || null,
            columsObj = [];

        if (tableFields) {
            tableFields = ["no"].concat(tableFields);

            if (tableFields?.length > 5)
                tableFields = tableFields.filter((_field)=>['no', 'date', 'category', 'subcategory', 'reference', 'currency', 'total-local-claim-amount'].indexOf(_field) !== -1)

            tableFields.map((fieldKey, i) => {
                let tabObj = formDetail?.data?.table_fields[0],
                    field = tabObj[fieldKey] || fieldKey;

                if (fieldKey === "no") {
                    field = { type: "integer", label: "No.", value: "no", slug: "no" };
                }

                return field && field?.type !== "hidden"
                    ? columsObj?.push({
                        ...field,
                        title: field?.label || convertToPascalCase(field?.slug),
                        dataIndex: fieldKey,
                        align: field?.type === 'float' || (!isNaN((field?.value + "").split(',').join('')) && (field?.value + "").indexOf(".") !== -1 && field?.type !== 'integer')  ? 'right' : 'left',
                        render: (data, dataRow) => {
                            const key = dataRow?.key;

                            const value =
                                field?.type === "file" ? (
                                    <a
                                        href={data}
                                        key={data}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {//eslint-disable-next-line
                                            data.replace(/^.*[\\\/]/, "")}
                                    </a>
                                ) : (
                                    data
                                );

                            if (field?.slug) {
                                return field?.slug === 'link-status' ? (
                                    <Badge
                                        className='status-label'
                                        variant='success'>
                                        {value}
                                    </Badge>
                                ) : (
                                    <div className='ml-8' key={key}>
                                        {value}
                                    </div>
                                );
                                //  <FormInput key={key} rowKey= {key} type="table-fields" isReadOnly ={true} field ={field} hideHelp={true}/>
                            }
                        },
                    })
                    : null;
            });

            return columsObj;
        }
    };

    const getSlipColumns = (slip) => {
        let tableFields = slip?.data?.table_layout || null,
            columsObj = [];
        // console.log('tableFields => ', tableFields);
        tableFields = tableFields.filter((_field)=>['no', 'date', 'category', 'subcategory', 'reference', 'currency', 'total-local-claim-amount'].indexOf(_field) !== -1)
        if (tableFields.length) {
            // tableFields = ['no'].concat(tableFields)

            tableFields.map((fieldKey, i) => {
                let tabObj = { no: {}, ...slip?.data?.table_fields[0] },
                    field = tabObj[fieldKey];

                if (fieldKey === "no") {
                    field = { type: "integer", label: "No.", value: "no" };
                }
                return field && field?.type !== "hidden"
                    ? columsObj?.push({
                        ...field,
                        title: field?.label || convertToPascalCase(field?.slug),
                        dataIndex: fieldKey,
                        align: field?.type === 'float' || (!isNaN((field?.value + "").split(',').join('')) && (field?.value + "").indexOf(".") !== -1 && field?.type !== 'integer') ? 'right' : 'left',
                        render: (data, dataRow) => {
                            const key = dataRow.key;

                            const value =
                                field?.type === "file" ? (
                                    <a
                                        href={data}
                                        key={data}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {//eslint-disable-next-line
                                            data?.replace(/^.*[\\\/]/, "")}
                                    </a>
                                ) : field?.type === "boolean" ? data ? 'Checked' : 'Unchecked' : (
                                    data
                                );

                            if (field) {
                                return (
                                    <div className="ml-8" key={key}>
                                        {value}
                                    </div>
                                );
                                //  <FormInput key={key} rowKey= {key} type="table-fields" isReadOnly ={true} field ={field} hideHelp={true}/>
                            }
                        },
                    })
                    : null;
            });

            return columsObj;
        }
    };

    const getDataSource = () => {
        let rowData = [];

        formDetail.data.table_layout && formDetail.data.table_fields.map((field, i) => {
            let computeObj = { no: i + 1 };

            formDetail.data.table_layout.map((fieldKey) => {
                if(!field[fieldKey]?.type?.includes('integer')){
                    field[fieldKey] = getFormattedNumber(field[fieldKey]);
                }
                // console.log(field[fieldKey])
                if(field[fieldKey]?.type !== 'hidden'){
                    computeObj[fieldKey] = field[fieldKey]?.value;
                    return computeObj;
                }
            });
            computeObj["key"] = i + 1
            rowData.push(computeObj);

            return true;
        });

        return rowData;
    };

    const getSlipDataSource = (slip) => {
        let rowData = [];
        // console.log('slip =>' , slip.data)
        slips && slip.data && slip.data.table_fields && slip.data.table_fields.map((field, i) => {
            let computeObj = { no: i + 1};

            slip && slip.data && slip.data?.table_layout && slip.data.table_layout.map(
                (fieldKey) => {
                    if(fieldKey !== 'no' && (field[fieldKey]?.type === 'float' || field[fieldKey]?.type === 'readonly-float')){
                        field[fieldKey] = getFormattedNumber(field[fieldKey]);
                    }
                    if(fieldKey === "date-incurred"){
                        computeObj[fieldKey] = field[fieldKey] ? field[fieldKey].value ||  field["date"]?.value || "" : '';
                    }
                    else{
                        computeObj[fieldKey] = field[fieldKey] ? field[fieldKey].value : '';
                    }
                    return computeObj;
                });

                computeObj["key"] = i + 1

                if(Object.keys(computeObj).length > 2) rowData.push(computeObj);

            return true;
        });

        return rowData;
    };

    const getApproversList = () => {
        let approversArray = formDetail?.approvals || [],
            executorArray = formDetail?.executions || [],
            data = [];

        approversArray.map((list, i) => {
            const { approved_by, status, modified, remarks, action_role } = list || {};

            let firstName =
                approved_by && approved_by.first_name
                    ? approved_by.first_name
                    : "",
                lastName =
                    approved_by && approved_by.last_name
                        ? approved_by.last_name
                        : "",
                fullName =
                    firstName || lastName
                        ? firstName + " " + lastName
                        : approved_by?.username,
                date = modified
                    ? moment(list.modified).format("MMMM Do YYYY, h:mm:ss a")
                    : "";

            let actionRole = action_role ? action_role : ''

            data.push({
                name: fullName,
                actionRole: actionRole,
                status,
                actionDate: date,
                remark: remarks,
                type: "Approver",
                serial: i + 1,
            });

            return list;
        });
        executorArray.map((list, i) => {
            const { action_role, status, modified, remarks, executed_by } = list || {};

            let firstName = executed_by && executed_by.first_name ? executed_by.first_name : "";
            let lastName = executed_by && executed_by.last_name ? executed_by.last_name : "";
            let fullName = firstName || lastName ? firstName + " " + lastName : executed_by?.username;

            let date = modified
                ? moment(list.modified).format("MMMM Do YYYY, h:mm:ss a")
                : "";

            data.push({
                name: action_role || fullName,
                status,
                actionDate: date,
                remark: remarks,
                type: "Executor",
                serial: i + 1,
            });

            return list;
        });

        return data;
    };

    const viewSlip = (slip) => {
        // slip-form
        // TODO: due to this screen is flickering which is destroying user experience
        resetFormData();
        // console.log(detailForm);
        setSlipData(slip);
        setIsShowSlip(true);
    };

    const getVariant = (status) => {
        let variant = "success";

        if (status === "pending" || status === "cancelled") variant = "warning";
        else if (status === "complete" || status === "approved") variant = "success";
        else if (status === "rejected") variant = "danger";

        return variant;
    };

    useEffect(() => {
        setLoading(true);

        let executorArray = formDetail?.executions || [],
            approvalsArray = formDetail?.approvals || [],
            slip = [],
            approvedSlip = [],
            completedSlip = [];

        executorArray.map((list, i) => {
            let editableSlip = list.slips.filter(
                (slip) => slip.state === "editable"
            );

            return (slip = slip.concat(editableSlip));
        });

        approvalsArray.map((list) => {
            if (list?.amend_slip?.state === "editable")
                slip.push(list.amend_slip);
            return list;
        });
        // console.log(executorArray)
        approvalsArray.filter(item => item.status === "approved").map((list) => {
            if (list?.amend_slip) {
                list.amend_slip.approved_by = list?.approved_by?.username;
                approvedSlip.push(list?.amend_slip);
            }
            return list;
        });


        executorArray.forEach((list, i) => {
            let completeSlip = list?.slips?.filter(
                (slip) => slip?.state === "readonly" && Object.keys(slip?.data).length > 0
            ).map(slip => { slip.completed_by = list?.executed_by?.username; return slip; });;
            if (completeSlip?.length > 0) {
                completedSlip.push(...completeSlip);
            }

        });
        
        if (setRemarks) setRemarks(remarks);
        setSlips(slip);
        setApprovedSlips(approvedSlip);
        setCompletedSlips(completedSlip);
        setLoading(false);
    }, [formDetail, setRemarks, remarks, setSlips]);

    if (showSuccessToaster) {
        return (
            <Snackbar
                open={true}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                autoHideDuration={100}
                onClose={handleClose}
            >
                <Alert severity="success">{"Successfully submitted"}</Alert>
            </Snackbar>
        );
    }
    if (showToaster) {
        return (
            <Snackbar
                open={showToaster}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                autoHideDuration={1000}
                onClose={handleClose}
            >
                <Alert severity="warning">
                    {errorMessage || "Something went wrong"}
                </Alert>
            </Snackbar>
        );
    }

    const expandAll = (key, rows) => {
        if (!expandedKeys[key]) expandedKeys[key] = []
        if (expandedKeys[key]?.length === 0){
            expandedKeys[key] = rows.map((row)=>row.key)
            setExpandedKeys({...expandedKeys})
            console.log(expandedKeys)
        }else{
            expandedKeys[key] = []
            setExpandedKeys({...expandedKeys})
        }
    }

    return (
        <React.Fragment>
            {loading ? (
                <LayoutSplashScreen />
            ) : (
                <React.Fragment>
                    <div className="view-form">
                    {/* container */}
                        <div className="row">
                            {formDetail?.data?.layout &&
                                formDetail.data.layout.length > 0
                                ? formDetail.data.layout.map(
                                    (formKey, index) => (
                                        <React.Fragment key={`view-form-detail-data-fields-${index}`}>
                                            {formDetail?.data?.fields[formKey]
                                                ?.type !== "hidden" ? (
                                                <FormInput
                                                    key={'row1-' + index}
                                                    isReadOnly={true}
                                                    formName={formDetail.name}
                                                    field={
                                                        formDetail.data
                                                            .fields[formKey]
                                                    }
                                                />
                                            ) : null}
                                        </React.Fragment>
                                    )
                                )
                                : null}
                            {formDetail?.data?.magic_link && (
                                <MagicLinkTable id={formDetail?.data?.magic_link}/>
                            )}
                            {formDetail?.data?.table_fields &&
                                formDetail?.data?.table_fields.length > 0 ? (
                                <div className="table-fields">
                                    <div className="flex row justify-content-end ml-1 mr-1 bg-light">
                                        {formDetail?.data?.table_layout?.length > 5 &&
                                            <button
                                            type="button"
                                            className="btn btn-info btn-sm m-2"
                                            onClick={(e)=>expandAll("table-fields", getDataSource())}
                                            >
                                                {!expandedKeys["table-fields"] ||
                                                expandedKeys["table-fields"]?.length === 0 ?
                                                "Expand All +" :
                                                "Collapse All -"
                                            }
                                            </button>
                                        }
                                    </div>
                                    <Table
                                        dataSource={getDataSource() || []}
                                        columns={getColumns()}
                                        pagination={false}
                                        expandable={{
                                            defaultExpandAllRows:true,
                                            expandedRowRender: formDetail?.data?.table_layout?.length >= 5 ? (row)=>{
                                                let rowData = formDetail?.data?.table_layout?.filter((_field)=>(['no', 'date', 'category', 'subcategory', 'reference', 'currency', 'total-local-claim-amount'].indexOf(_field) === -1) && _field)
                                                return<table className="ml-8">
                                                    {
                                                        rowData.map((key, index)=>{
                                                            let _field = formDetail?.data?.table_fields[row['no'] - 1]
                                                            // return (<p style={{ margin: 0}}>{key}: {row[key]}</p>)

                                                            const value =
                                                                _field[key]?.type === "file" ? (
                                                                    <a
                                                                        href={row[key]}
                                                                        key={row[key]}
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        {//eslint-disable-next-line
                                                                            row[key].replace(/^.*[\\\/]/, "")
                                                                        }
                                                                    </a>
                                                                ) : (
                                                                    row[key]
                                                                );

                                                            if (_field[key] && value) {
                                                                return (
                                                                    <tr className="ml-8" key={key}>
                                                                    <td>{convertToPascalCase(_field[key]?.label)}</td><td>{value}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </table> 
                                            }: null,
                                                // <Table
                                                //     // className="p-6"
                                                //     pagination={false}
                                                //     indentSize={0}
                                                //     bordered={true}
                                                //     tableLayout="fixed"
                                                //     dataSource={[row]}
                                                //     columns={getColumns(true)}
                                                //     scroll={{ x: 'fit-content' }}
                                                // />,
                                            onExpand: (expanded, row) => {
                                                if (expanded){
                                                    expandedKeys['table-fields'] = [...expandedKeys['table-fields'], row.key]
                                                }
                                                else{
                                                    expandedKeys['table-fields'] = expandedKeys['table-fields'].filter((key)=>key !== row.key)
                                                }
                                                setExpandedKeys({...expandedKeys})
                                            },
                                            expandedRowKeys: expandedKeys['table-fields']
                                            
                                        }}
                                        // scroll={{
                                        //     x: 'fit-content',
                                        //         // getColumns() &&
                                        //         // getColumns().length * 150,
                                        //     // y: 200,
                                        // }}
                                    />
                                </div>
                            ) : null}
                            {formDetail?.data?.tally_layout &&
                                formDetail.data.tally_layout.length > 0 ? (
                                <div className="tally">
                                    {" "}
                                    {formDetail.data.tally_layout.map(
                                        (formKey, index) => (
                                            <div
                                                className="tally-row"
                                                key={'tally-row-' + index}
                                            >
                                                <FormInput
                                                    type="tally-fields"
                                                    isReadOnly={true}
                                                    field={
                                                        formDetail.data
                                                            .tally_fields[
                                                        formKey
                                                        ]
                                                    }
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : null}
                        </div>
                        <div className="row">
                            {getApproversList()?.length ? (
                                <List
                                    key={'view-form-list-1-'}
                                    footer={<div></div>}
                                    dataSource={getApproversList()}
                                    renderItem={(item, index) => (
                                        <List.Item key={'view-form-list-' + index}>
                                            <div className="col-md-12 d-flex flex-row-fluid">
                                                <div className="col-md-6">
                                                    <div className="col-md-12">
                                                        {item.type} {item.serial} :{" "}
                                                        {item.name ? item.name : item.actionRole}
                                                    </div>
                                                    {item.type && !["waiting", "pending"].includes(item.status) ? (
                                                        <>
                                                            <div className="col-md-12 text-muted">
                                                                Action on{" "}
                                                                {item.actionDate}
                                                            </div>
                                                            <div className="col-md-12 text-muted">
                                                                Remarks :{" "}
                                                                {item.remark}
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </div>
                                                <div className="col-md-6 text-center">
                                                    <Badge
                                                        className="status-label"
                                                        variant="light"
                                                    >
                                                        Status
                                                    </Badge>
                                                    <Badge
                                                        className="status-label"
                                                        variant={getVariant(
                                                            item.status
                                                        )}
                                                    >
                                                        {item?.status}
                                                    </Badge>
                                                </div>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            ): <></>}
                        </div>
                    </div>

                    {completedSlips && completedSlips.length > 0 ? (
                        <div className="view-form">
                            {completedSlips.map((slip, i) => (
                                <>
                                    {slip.data ? (
                                        <div className="mt-5 row" key={'view-form-card-row-' + i}>
                                            <Card
                                                key={'view-form-card-1-'}
                                                title={`${slip.name} Approved by : (${slip.completed_by})`}
                                                type="inner"
                                                className="card"
                                            >

                                                {slip?.data?.layout &&
                                                    slip.data.layout.length > 0
                                                    ? slip.data.layout.map(
                                                        (formKey, index) => {
                                                            let data = {...slip?.data?.fields[formKey], slug: formKey}
                                                            
                                                            return (
                                                            <>
                                                                {slip?.data
                                                                    ?.fields[
                                                                    formKey
                                                                ]?.type !==
                                                                    "hidden" ? (
                                                                    <FormInput
                                                                        key={
                                                                            'view-form-' + formKey + '-' + index
                                                                        }
                                                                        isReadOnly={
                                                                            true
                                                                        }
                                                                        formName={
                                                                            slip.name
                                                                        }
                                                                        field={data
                                                                            // slip
                                                                            //     .data
                                                                            //     .fields[
                                                                            // formKey
                                                                            // ]
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </>
                                                        )}
                                                    )
                                                    : null}
                                                {slip?.data?.table_fields && slip?.data?.table_fields.length > 0 ? (
                                                    <div className="table-fields">
                                                        <div className="flex row justify-content-end ml-1 mr-1 bg-light">
                                                            <button
                                                                type="button"
                                                                className="btn btn-info btn-sm m-2"
                                                                onClick={(e)=>expandAll("complete-slip-table-fields-" + i, getDataSource())}
                                                            > 
                                                                {!expandedKeys["complete-slip-table-fields-" + i] || 
                                                                expandedKeys["complete-slip-table-fields-" + i]?.length === 0? 
                                                                "Expand All +":
                                                                "Collapse All -"
                                                                }
                                                            </button>
                                                        </div>
                                                        <Table
                                                            dataSource={
                                                                getSlipDataSource(
                                                                    slip
                                                                ) || []
                                                            }
                                                            columns={getSlipColumns(
                                                                slip
                                                            )}
                                                            pagination={false}
                                                            expandable={{
                                                                defaultExpandAllRows: true,
                                                                expandedRowRender: (row)=>{
                                                                    let rowData = slip?.data?.table_layout?.filter((_field)=>{if (['no', 'date', 'category', 'subcategory', 'reference', 'currency', 'total-local-claim-amount'].indexOf(_field) === -1) return _field})
                                                                    return<table className="ml-8">
                                                                        {
                                                                            rowData.map((key, index)=>{
                                                                                let _field = slip?.data?.table_fields[row['no'] - 1]
                                                                                // return (<p style={{ margin: 0}}>{key}: {row[key]}</p>)
                    
                                                                                const value =
                                                                                    _field[key]?.type === "file" ? (
                                                                                        <a
                                                                                            href={row[key]}
                                                                                            key={row[key]}
                                                                                            rel="noopener noreferrer"
                                                                                            target="_blank"
                                                                                        >
                                                                                            {//eslint-disable-next-line
                                                                                                row[key].replace(/^.*[\\\/]/, "")
                                                                                            }
                                                                                        </a>
                                                                                    ) : (
                                                                                        row[key]
                                                                                    );
                    
                                                                                if (_field[key] && value) {
                                                                                    return (
                                                                                        <tr className="ml-8" key={key}>
                                                                                        <td>{convertToPascalCase(_field[key]?.label)}</td><td>{value}</td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            }
                                                                        )}
                                                                    </table> 
                                                                },
                                                                onExpand: (expanded, row) => {
                                                                    if (expanded){
                                                                        expandedKeys["complete-slip-table-fields-" + i] = [...expandedKeys["complete-slip-table-fields-" + i], row.key]
                                                                    }
                                                                    else{
                                                                        expandedKeys["complete-slip-table-fields-" + i] = expandedKeys["complete-slip-table-fields-" + i].filter((key)=>key !== row.key)
                                                                    }
                                                                    setExpandedKeys({...expandedKeys})
                                                                },
                                                                expandedRowKeys: expandedKeys["complete-slip-table-fields-" + i]
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                                {slip?.data?.tally_layout &&
                                                    slip.data.tally_layout.length >
                                                    0 ? (
                                                    <div className="tally">
                                                        {" "}
                                                        {slip.data.tally_layout.map(
                                                            (
                                                                formKey,
                                                                index
                                                            ) => (
                                                                <div
                                                                    className="tally-row"
                                                                    key={'tally-row-div' + index}
                                                                >
                                                                    <FormInput
                                                                        type="tally-fields"
                                                                        isReadOnly={
                                                                            true
                                                                        }
                                                                        field={
                                                                            slip
                                                                                .data
                                                                                .tally_fields[
                                                                            formKey
                                                                            ]
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : null}
                                            </Card>
                                        </div>
                                    ) : null}
                                </>
                            ))}
                        </div>
                    ) : null}

                    {approvedSlips && approvedSlips.length > 0 ? (
                        <div className="view-form">
                            {approvedSlips.map((slip, i) => (
                                <>
                                    {slip.data ? (
                                        <div className="mt-5 row" key={'view-form-slip1-' + i}>
                                            <Card
                                                key={'view-form-card-2-'}
                                                title={`${slip.name} Approved by : (${slip.approved_by})`}
                                                type="inner"
                                                className="card"
                                            >

                                                {slip?.data?.layout &&
                                                    slip.data.layout.length > 0
                                                    ? slip.data.layout.map(
                                                        (formKey, index) => (
                                                            <>
                                                                {slip?.data
                                                                    ?.fields[
                                                                    formKey
                                                                ]?.type !==
                                                                    "hidden" ? (
                                                                    <FormInput
                                                                        key={
                                                                            'view-form-slip1-div1-' + index
                                                                        }
                                                                        isReadOnly={
                                                                            true
                                                                        }
                                                                        formName={
                                                                            slip.name
                                                                        }
                                                                        field={
                                                                            slip
                                                                                .data
                                                                                .fields[
                                                                            formKey
                                                                            ]
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </>
                                                        )
                                                    )
                                                    : null}
                                                {slip?.data?.table_fields && slip?.data?.table_fields.length > 0 ? (
                                                    <div className="table-fields">
                                                        <div className="flex row justify-content-end ml-1 mr-1 bg-light">
                                                            <button
                                                                type="button"
                                                                className="btn btn-info btn-sm m-2"
                                                                onClick={(e)=>expandAll("approved-slip-table-fields-" + i, getDataSource())}
                                                            > 
                                                                {!expandedKeys["approved-slip-table-fields-" + i] || 
                                                                expandedKeys["approved-slip-table-fields-" + i]?.length === 0 ?
                                                                "Expand All +" : 
                                                                "Collapse All -"
                                                                }
                                                            </button>
                                                        </div>
                                                        <Table
                                                            dataSource={
                                                                getSlipDataSource(
                                                                    slip
                                                                ) || []
                                                            }
                                                            columns={getSlipColumns(
                                                                slip
                                                            )}
                                                            pagination={false}
                                                            expandable={{
                                                                defaultExpandAllRows: true,
                                                                expandedRowRender: (row)=>{
                                                                    let rowData = slip?.data?.table_layout?.filter((_field)=>{if (['no', 'date', 'category', 'subcategory', 'reference', 'currency', 'total-local-claim-amount'].indexOf(_field) === -1) return _field})
                                                                    return<table className="ml-8">
                                                                        {
                                                                            rowData.map((key, index)=>{
                                                                                let _field = slip?.data?.table_fields[row['no'] - 1]
                                                                                // return (<p style={{ margin: 0}}>{key}: {row[key]}</p>)
                    
                                                                                const value =
                                                                                    _field[key]?.type === "file" ? (
                                                                                        <a
                                                                                            href={row[key]}
                                                                                            key={row[key]}
                                                                                            rel="noopener noreferrer"
                                                                                            target="_blank"
                                                                                        >
                                                                                            {//eslint-disable-next-line
                                                                                                row[key].replace(/^.*[\\\/]/, "")
                                                                                            }
                                                                                        </a>
                                                                                    ) : (
                                                                                        row[key]
                                                                                    );
                    
                                                                                if (_field[key]) {
                                                                                    return (
                                                                                        <tr className="ml-8" key={key}>
                                                                                        <td>{convertToPascalCase(_field[key]?.label)}</td><td>{value}</td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            }
                                                                        )}
                                                                    </table> 
                                                                },
                                                                onExpand: (expanded, row) => {
                                                                    if (expanded){
                                                                        expandedKeys["approved-slip-table-fields-" + i] = [...expandedKeys["approved-slip-table-fields-" + i], row.key]
                                                                    }
                                                                    else{
                                                                        expandedKeys["approved-slip-table-fields-" + i] = expandedKeys["approved-slip-table-fields-" + i].filter((key)=>key !== row.key)
                                                                    }
                                                                    setExpandedKeys({...expandedKeys})
                                                                },
                                                                expandedRowKeys: expandedKeys["approved-slip-table-fields-" + i]
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                                {slip?.data?.tally_layout &&
                                                    slip.data.tally_layout.length >
                                                    0 ? (
                                                    <div className="tally">
                                                        {" "}
                                                        {slip.data.tally_layout.map(
                                                            (
                                                                formKey,
                                                                index
                                                            ) => (
                                                                <div
                                                                    className="tally-row"
                                                                    key={'view-form-tally-row-' + index}
                                                                >
                                                                    <FormInput
                                                                        type="tally-fields"
                                                                        isReadOnly={
                                                                            true
                                                                        }
                                                                        field={
                                                                            slip
                                                                                .data
                                                                                .tally_fields[
                                                                            formKey
                                                                            ]
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : null}
                                            </Card>
                                        </div>
                                    ) : null}
                                </>
                            ))}
                        </div>
                    ) : null}

                    {slips && slips.length > 0 ? (
                        <div className="view-form">
                            {slips.map((slip, i) => (
                                <>
                                    {slip.data && (Object.keys(slip.data).length !== 0 || slip.is_approver || slip.is_executor) ? (
                                        <div className="mt-5 row" key={'view-form-slip-card-row-' + i}>
                                            <Card
                                                key={'view-form-slip-card-self-' + i}
                                                title={`${slip.name}`}
                                                type="inner"
                                                className="card"
                                                extra={((getStatus !== 'ongoing') && detailForm?.status !== 'ongoing') && (slip.is_approver || slip.is_executor) && (
                                                    <Button className='edit-btn' onClick={() => viewSlip(slip)}>Edit</Button>)
                                                }
                                            >
                                                {slip?.data?.layout &&
                                                    slip.data.layout.length > 0
                                                    ? slip.data.layout.map(
                                                        (formKey, index) => (
                                                            <>
                                                                {slip?.data
                                                                    ?.fields[
                                                                    formKey
                                                                ]?.type !==
                                                                    "hidden" ? (
                                                                    <FormInput
                                                                        key={
                                                                            'view-form-slip-card-layout' + index
                                                                        }
                                                                        isReadOnly={
                                                                            true
                                                                        }
                                                                        formName={
                                                                            slip.name
                                                                        }
                                                                        field={
                                                                            slip
                                                                                .data
                                                                                .fields[
                                                                            formKey
                                                                            ]
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </>
                                                        )
                                                    )
                                                    : null}
                                                {slip?.data?.table_fields &&
                                                    slip?.data?.table_fields
                                                        .length > 0 ? (
                                                    <div className="table-fields">
                                                        <div className="flex row justify-content-end ml-1 mr-1 bg-light">
                                                            <button
                                                                type="button"
                                                                className="btn btn-info btn-sm m-2"
                                                                onClick={(e)=>expandAll("slip-table-fields-" + i, getDataSource())}
                                                            > 
                                                                {!expandedKeys["slip-table-fields-" + i] ||
                                                                expandedKeys["slip-table-fields-" + i]?.length === 0 ?
                                                                "Expand All +" :
                                                                "Collapse All -"
                                                                }
                                                            </button>
                                                        </div>
                                                        <Table
                                                            key={i}
                                                            dataSource={
                                                                getSlipDataSource(
                                                                    slip
                                                                ) || []
                                                            }
                                                            columns={getSlipColumns(
                                                                slip
                                                            )}
                                                            pagination={false}
                                                            expandable={{
                                                                defaultExpandAllRows: true,
                                                                expandedRowRender: (row)=>{
                                                                    let rowData = slip?.data?.table_layout?.filter((_field)=>{if (['no', 'date', 'category', 'subcategory', 'reference', 'currency', 'total-local-claim-amount'].indexOf(_field) === -1) return _field})
                                                                    return<table className="ml-8">
                                                                        {
                                                                            rowData.map((key, index)=>{
                                                                                let _field = slip?.data?.table_fields[row['no'] - 1]
                                                                                // return (<p style={{ margin: 0}}>{key}: {row[key]}</p>)
                    
                                                                                const value =
                                                                                    _field[key]?.type === "file" ? (
                                                                                        <a
                                                                                            href={row[key]}
                                                                                            key={row[key]}
                                                                                            rel="noopener noreferrer"
                                                                                            target="_blank"
                                                                                        >
                                                                                            {//eslint-disable-next-line
                                                                                                row[key].replace(/^.*[\\\/]/, "")
                                                                                            }
                                                                                        </a>
                                                                                    ) : (
                                                                                        row[key]
                                                                                    );
                    
                                                                                if (_field[key]) {
                                                                                    return (
                                                                                        <tr className="ml-8" key={key}>
                                                                                        <td>{convertToPascalCase(_field[key]?.label)}</td><td>{value}</td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            }
                                                                        )}
                                                                    </table> 
                                                                },
                                                                onExpand: (expanded, row) => {
                                                                    if (expanded){
                                                                        expandedKeys["slip-table-fields-" + i] = [...expandedKeys["slip-table-fields-" + i], row.key]
                                                                    }
                                                                    else{
                                                                        expandedKeys["slip-table-fields-" + i] = expandedKeys["slip-table-fields-" + i].filter((key)=>key !== row.key)
                                                                    }
                                                                    setExpandedKeys({...expandedKeys})
                                                                },
                                                                expandedRowKeys: expandedKeys["slip-table-fields-" + i]
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                                {slip?.data?.tally_layout &&
                                                    slip.data.tally_layout.length >
                                                    0 ? (
                                                    <div className="tally">
                                                        {" "}
                                                        {slip.data.tally_layout.map(
                                                            (
                                                                formKey,
                                                                index
                                                            ) => (
                                                                <div
                                                                    className="tally-row"
                                                                    key={'view-form-tally-row-' + index}
                                                                >
                                                                    <FormInput
                                                                        type="tally-fields"
                                                                        isReadOnly={
                                                                            true
                                                                        }
                                                                        field={
                                                                            slip
                                                                                .data
                                                                                .tally_fields[
                                                                            formKey
                                                                            ]
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : null}
                                            </Card>
                                        </div>
                                    ) : null}
                                </>
                            ))}
                        </div>
                    ) : null}
                    {isShowRemarks ? (
                        <div className="mt-5 remark-field">
                            <FormInput
                                key={'remark-field-input'}
                                field={remarkField}
                                changeEvent={handleChange}
                                type="table-fields"
                            />
                        </div>
                    ) : null}
                    {/* slip-form */}
                    {isShowSlip ? (
                        <ModalContainer
                            isOpen={isShowSlip}
                            onClose={handleCancel}
                            modalBody={
                                <Form
                                    key={`slip-form-${slipData.id}`}
                                    type="slip-form"
                                    slipData={slipData}
                                    inputData={inputData}
                                    setInputData={setInputData}
                                    formName={slipData.form}
                                    valuesToPreFill={detailForm}
                                />
                            }
                            modalFooter={
                                <div className="d-flex justify-content-end w-100">
                                    <button
                                        type="submit"
                                        className="btn btn-secondary ml-2"
                                        onClick={(e) => handleCancel(e)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary ml-2"
                                        onClick={() => handleOk(slipData.id)}
                                    >
                                        Submit
                                    </button>
                                </div>
                            }
                        />
                    ) : null}
                </React.Fragment>
            )}
            {isShowPdfModal && (
                <ModalContainer
                    isOpen={isShowPdfModal}
                    onClose={handleDismissPdfModal}
                    modalBody={
                        <div className="col" style={{ display: 'flex', alignItems:'center', flexWrap: 'wrap', flexDirection: 'column' }}>
                            <EquipmentPDF
                                key='equipmentPdf'
                                pdfUrl={pdfUrl}
                                pdfLoading={pdfLoading}
                                // fieldValue={detailForm?.data?.fields}
                                // tableFieldValue={detailForm?.data?.table_fields}
                                // tallyFieldValue={detailForm?.data?.tally_fields}
                                // detailForm={detailForm}
                            />
                        </div>
                    }
                    modalFooter={
                        <div className="d-flex justify-content-end w-100">
                            <div className="btn btn-info mr-2" onClick={() => handleDismissPdfModal()}>
                                Cancel
                            </div>
                                <div className="btn btn-info mr-2" style={{ cursor: pdfLoading && 'not-allowed' }} onClick={!pdfLoading ? (e) => printDocument(e) : (e)=>null}>
                                    {!pdfLoading ?
                                        <>Export to PDF</>
                                        : <div className='d-flex align-items-center'>
                                                <div className='mr-2 text-muted'>Loading...</div>
                                                <div className='spinner spinner-primary mr-10'></div>
                                            </div>
                                    }
                                </div>
                        </div>
                    }
                />
            )}
        </React.Fragment>

    );
};

const mapDispatchToProps = (dispatch) => ({
    resetFormData: (data) => dispatch(resetTotalFormData(data)),
});
const mapStateToProps = createStructuredSelector({
    formData: selectFormData,
    totalFormValue: selectForms,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewForm);
