import store from '../../../redux/store'

const parseCookie = (str) => {
	return str
		?.split(';')
		?.map((v) => v?.split('='))
		?.reduce((acc, v) => {
			acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(v[1]?.trim());
			return acc;
		}, {});
};

export const getAuthToken = async() => {

    const state = store?.getState();
    
    // let token = localStorage.getItem('token');
    let token = state?.auth?.authToken;

    if (!token) {
        const cookie = document.cookie;
        if (cookie !== '' && cookie !== null) {
            let cookieObj = parseCookie(cookie) //JSON.parse(cookie);
            token = cookieObj && cookieObj.token;
        }
    }
    // console.log('token => ', token)
    return token;
};

export const setAuthToken = (token) => {
    let hasToken = getAuthToken();

    if (!hasToken && token) {
        document.cookie = JSON.stringify({ token });
    }

    return token;
};

export const FIELD_TYPES = {
    text: 'text',
    float: 'float',
    dropdown: 'dropdown',
    textinput: 'textinput',
    textarea: 'textarea',
    boolean: 'boolean',
    date: 'date',
    integer: 'integer',
    file: 'file',
    hidden: 'hidden',
};

export const TYPES_OF_FORMS = {
    MANPOWER: 'manpower',
    COMMISION: 'commission-requisition',
    LEAVE: 'leave',
    TRAINING: 'training',
    CLAIM: 'claim',
    CREDIT: 'credit-limit-request',
    LOAN: 'loanrentdemo',
    PURCHASE: 'purchase',
};