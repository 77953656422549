import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { apiForgotPassword } from "../../modules/services/api";
import { notification } from "antd";
import { getResponseErrorToString } from "../../utils/common-functions";

const initialValues = {
    username: "",
    email: "",
};

const ForgotPassword = (props) => {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            // .min(3, "Minimum 3 symbols")
            // .max(50, "Maximum 50 symbols")
            .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
        email: Yup.string()
            .email('Must be a valid email')
            .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const onForgotPasswordSubmit = async (values, { setStatus, setSubmitting }) => {
        try {
            const res = await apiForgotPassword(values.email, values.username)
            // console.log(res)
            notification.success({ message: res?.data?.detail || 'Reset password link has been sent to your email' });
        } catch (err) {
            console.log(err.response.data);
            if (err?.response?.data) {
                const errStr = getResponseErrorToString(err?.response?.data)
                console.log(errStr)
                notification.error({ message: errStr });
            } else if (setStatus) {
                setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_FIELD" }));
            }
        }
        if (setSubmitting) {
            setSubmitting(false);
        }
        disableLoading();
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, otherParams) => {
            enableLoading();
            onForgotPasswordSubmit(values, otherParams);
        },
    });

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.FORGOT.TITLE" />
                </h3>
                <p className="text-muted font-weight-bold">
                    <FormattedMessage id="AUTH.FORGOT.DESC" />
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                {formik.status ? (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {formik.status}
                        </div>
                    </div>
                ) : null}

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Username"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("username")}`}
                        name="username"
                        {...formik.getFieldProps("username")}
                    />
                    {formik.touched.username && formik.errors.username ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.username}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Email"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
                        name="email"
                        {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.email}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group">
                    <Link to="/auth/login" className="link-primary fs-6 fw-bolder">Back to login</Link>
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary font-weight-bold d-block  mx-auto rounded-0`}
                        style={loading ? { paddingRight: 30 } : null}
                    >
                        <span>Send Email</span>
                        {loading && (<span className="ml-3 spinner spinner-white"></span>)}
                    </button>

                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl((ForgotPassword));
